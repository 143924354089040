.bloc-contact{
  &__wrapper{
    display:block;
    padding-right: 0;
  }
  &__listitems{
    margin-top: 1.9em;
  }
  &__title{
    font-size: 1.4em;
  }
  .list-infos{
   &__phone{
     &:before{
       display:none;
     }
     a{
       padding-left:em(1.5, 1);
       @include default-icons-absolute-before('\e02b', em(1.4, 1.4), $color-1--2, 0, inherit, inherit, 0);
       &:before{
         display:inline-block;
       }
     }
   }
  }
}
.section-main__aside{
  > .bloc-contact{
    .list-infos{
      margin-top: 1em;
    }
  }
}
