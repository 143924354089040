// @state .home - Customize on the home
.home {
    .ddm-menu-service {
        .menu-service {
            .ddm__title {
                top: inherit;
                text-align: center;
                padding: 1em 0 !important;
                br {
                    display: none;
                }
                button {
                    min-width: inherit;
                    min-height: inherit;
                    font-family: $typo-3;
                    border: none;
                    border-radius: 0;
                    background: $color-white;
                    color: $color-3--3;
                    font-size: 1.2em;
                    text-transform: uppercase;
                    font-weight: $bold;
                    padding: 2px 0 0 25px;
                    margin-left: 10px;
                    @include default-icons-absolute-before('\e014', 13px, $color-3--3, 50%, inherit, inherit, 0);
                    &:before {
                        border: 1px solid $color-3--3;
                        width: 19px;
                        height: 19px;
                        line-height: 19px;
                        border-radius: 50%;
                        transition: all ease .2s;
                        transform: translateY(-50%);
                    }

                    &::after {
                        display: none;
                    }
                }
            }
        }
        &.ddm--active {
            .menu-service {
                .ddm__title {
                    button {
                        &:before {
                            content: "\e015";
                        }
                    }
                }
            }
        }
    }
    .header__middle {
        margin: 0 1.9em;
        overflow-x: hidden;
    }

    .service .col2 {
        margin-bottom: 1rem;
    }
}
