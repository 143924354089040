// @name Main menu

.home {
    .menu-main {
        top: 70px;
    }
}
.menu-main {
    display: none;
    background: transparent;
    position: absolute;
    width: 100%;
    top: 110px;
    left: 0;
    li {
        width: 100%;
        display: block;
        margin: 0;
    }
    &__level-1 {
        background: transparent url(../Images/bg/header-bg.png) repeat;
        padding: 0;
        z-index: 1;
        ul {
            height: auto;
        }
        & > li {
            & > a {
                border-top: 1px solid darken($color-3--3, 5%);
                padding: 1.45em 2.5em 1.45em 28px;
                &:active, &:hover, &:focus,
                &.active {
                    color: $color-1--1;
                    border-color: $color-2--3;
                }
            }
        }
    }
    &__sublink-1 {
        text-align: left;
        padding-left: 2.8em;
        &:after {
            display: block;
        }
        &:active, &:hover, &:focus {
            &:after {
                color: $color-1--1;
            }
        }
        &.active {
            z-index: 4;
            &:after {
                content: "\e00c";
                color: $color-1--1;
            }
        }
    }
    &__level-2-holder {
        position: inherit;
        top: inherit;
        z-index: 3;
        border-top: 1px solid $color-white;
        margin-top: 0;
    }
    &__level-2-wrap {
        position: relative;
        left: 0;
    }
    &__level-2 {
        display: block;
        width: 100%;
        padding: 0;
        z-index: 5;
        & > li {
            flex-wrap: wrap;
            align-items: flex-start !important;

            & > a {
                padding: 1.15em 3em 1.15em 32px;
                &:active, &:hover, &:focus {
                    color: $color-1--1;
                }
            }

            > .menu-main__link {
                width: calc(100% - 80px);
                flex-grow: 1;
                order: -1;
            }

            > .menu-level-trigger {
                margin-right: 0;
            }

            > .menu-main__sublink {
                width: 80px;
                height: 57px !important;

                .icon::before {
                    line-height: 7px;
                }
            }
        }
        .menu-main__sublink {
            .icon {
                width: 25px;
                height: 25px;
                top: 50%;
                right: 25px;
                left: inherit;
                margin: 0;
                &:before {
                    color: $color-black;
                }
            }
        }
    }
    &__level-3,
    &__level-4 {
        padding: 0;
        position: inherit;
        left: 0;
        &:before {
            display: none;
        }
        .menu-main__sublink {
            .icon {
                width: 21px;
                height: 21px;
                top: 52%;
                right: inherit;
                left: 31px;
            }
        }
        & > li {
            flex-wrap: wrap;

            & > a {
                padding: .8em 10px .8em 65px;
            }

            .menu-main__link {
                flex-grow: 1;
                box-sizing: border-box;
                margin-right: 0;
                width: calc(100% - 70px);
                padding-left: 25px !important;
            }

            .menu-main__sublink-3 {
                width: 70px;
                height: 57px;
                padding-left: 55px;
            }
        }
    }
    &__level-4 {
        .menu-main__sublink {
            width: 100%;

            .icon {
                top: 1.35em;
                right: inherit;
                left: 53px;
                &:before {
                    color: $color-white;
                }
            }
        }
        & > li {
            & > a {
                width: 100%;
                padding: .8em 10px .8em 79px;
            }
        }
    }
    button.menu-closer {
        display: none;
    }
    .menu-main__search {
        display: block;
    }
    .overlay-level-1 {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .4);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
    }
    .overlay-level-2 {
        z-index: 0;
    }
}
