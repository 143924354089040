.title-text{
  top: 11.3%;
  &__wrap{
    max-width: 23.2em;
    display: inline-block;
    font-weight: $bold;
    text-align: left;
    padding: 1em;
  }
  &__subtitle{
    font-size: 1.2em;
  }
  &__title{
    font-size: 2.4em;
    line-height: 1.2em;
    padding-right: 0;
  }
}
