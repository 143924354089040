.image-gallery{
  padding: 0;
  margin-bottom: 1%;
  .head{
    margin-bottom: 3.6em;
    max-width: 100%;
  }
  .title-3{
    margin-bottom: 0.22em;
    font-size: 3.2em;
  }
  &__list{
    display: none;
  }
  .images-hidden{
    display: block;
  }
  &__list2{
    .image-gallery__link{
      background: $color-3--14;
      &:hover{
        background: $color-2--3;
        .image-gallery__wrap-image,
        .videos__item{
          &:after{
            opacity: 0.8;
          }
        }
        .image-gallery__text{
          background: $color-2--3;
        }
      }
    }
    .image-gallery__text{
      margin: 0;
      padding: 2.1em 5em 1.7em;
      min-height: 1em;
      &:before{
        display: none;
        content: "";
      }
    }
    .image-gallery__text-legend{
      font-weight: $bold;
      font-size: 1.6em;
    }
  }
}
