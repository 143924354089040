// @name bloc directory

// @name Bloc directory in column
$bloc-chiffres__font-size:1;
.bloc-chiffres {
    text-align:center;
    .num {
        font-family: $typo-3;
        font-size:em(4, $bloc-chiffres__font-size);
        color:$color-2--3;
    }
    .teaser-1{
        font-family: $typo-1;
        font-size:em(1.5, $bloc-chiffres__font-size);
        line-height: em(1.15, $bloc-chiffres__font-size);
    }
    &__listitems{
        margin-top: 2.7em;
    }
}
