.title-text{
  position: relative;
  top: inherit;
  margin-top: -2.2em;
  &__wrap{
    max-width: 60em;
    display: block;
    padding-left: 28px;
    padding-right: 28px;
    margin: 0 auto;
  }
  &__subtitle{
    font-size: 1.4em;
  }
  &__title{
    font-size: 2.8em;
    line-height: 1.2em;
    color: $color-3--3;
    padding-right: 0;
  }
}
