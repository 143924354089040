.logo {
  left: 10px;
}
.content-page .logo,
.home .logo-nav-holder .logo{
  width: 58px;
  left: 10px;
  top: 7px;
  strong{
    font-size: 9px;
    letter-spacing: -0.2px;
    line-height: 13px;
  }
}
.home {
  .logo-holder{
    background: url(../Images/home-banner/banner-1.jpg) 0 0 no-repeat;
    background-size: cover;
    padding-bottom: 18%;
  }
  .header__middle{
    .logo{
      width: 100px;
      margin-top: 0;
      top: -22px;
      strong{
        font-size: 15px;
        line-height: 23px;
        margin-bottom: -1px;
      }
    }
  }
}
