.go-to-top {
  width: 100%;
  display: block;
  position: relative;
  right: 80px;
  z-index: 7000;
  &.fixed{
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    position: fixed;
    bottom: 50px;
  }
  p {
    height: 0;
    text-align: right;
  }
  .button-1 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: $color-2--3;
    position: relative;
    top: -32px;
    z-index: 7000;
    &:hover, &:active{
      background: $color-1--2;
      &:before {
        color: $color-black;
      }
    }
    &:before {
      content: "\e015";
      font-size: 17px;
      color: $color-white;
    }
  }
}
