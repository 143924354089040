// @name Liste type 1
// @description Basic list
.list-type-1 {
    margin-bottom: 5em;
    @extend .clear-fix;
    .list-type-1__date-2 {
        margin-top: em(-1, $date-2__font-size);
    }
}

.list-type-1__item {
    padding: 2em 0;
    @extend .clear-fix;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
    }
}

.list-type-1__picture {
    display: block;
    float: left;
    a{
        display: inline-block;
        &:hover{
            opacity: .9;
        }
    }
    img {
        margin-right: 2em;
        max-width: 220px;
        height: auto;
    }
}

.list-type-1__wrapper {
    overflow: hidden;
}

.list-type-1__title {
    font-family: $typo-3;
    color: $color-black;
    font-size: 2em;
    font-weight: $bold;
    margin: .2em 0 .8em;
    a {
        color: $color-black;
    }
}
