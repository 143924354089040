.bloc-annuaire{
  &__wrapper{
    display:block;
  }
  .list-infos {
    margin-bottom: 2.2em;
    &__item{
      max-width: 130px;
      margin: 0 auto;
    }
  }
  .bloc-left{
    text-align: center;
    margin-bottom: 1.5em;
    &.bloc-left__title{
      margin-bottom: 1.6em;
    }
  }
  &__item{
    padding-right: 0;
    margin-top: 1.9em;
  }
}
