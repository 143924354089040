$fil-infos__font-size: 1;

.fil-infos {
    padding-top: 3.1em;
    margin-bottom: 3.6em;
    &__wrap {
        padding-bottom: 0;
    }

    &__wrap-img {

        &::before {
            display: none !important;
        }
    }
    &__title {
        max-width: 55rem;
        margin-bottom: 2.8em;
        .link-all-dates {
            margin-top: 4px;
            display: block;
        }
        .title-3 {
            margin-bottom: 0;

            .inner {
                font-size: em(.88, $fil-infos__font-size);
                padding-right: 1em;
                padding-left: 1em;
            }
            &:before {
                margin-top: -1px;
            }
            .wrap {
                &:before,
                &:after {
                    font-size: em(.8, $fil-infos__font-size);
                }
                &:after {
                    right: -4px;
                }
            }
        }
        .link-all-dates {
            position: static;
            background: transparent;
            color: $color-black;
            font-weight: $light;
            font-size: em(1.2, $fil-infos__font-size);
            padding: 0;
            letter-spacing: 0;
            &:before {
                content: '/';
                position: static;
                transform: none;
                color: $color-black;
                font-size: 14px;
                margin-right: 3px;
            }
        }
    }
    &__paramount {
        display: table;
        text-align: center;
        margin: 0 auto 2em;
        width: 90%;
        &-wrap {
            width: inherit;
            display: table-row;
            &-img {
                display: inline-block;
                vertical-align: top;
            }
        }
        &-content {
            width: 1px;
            padding: 0 0 5.5em;
            text-align: center;
            display: table-cell;
            margin-left: 0;
            &:before {
                bottom: 100%;
                left: 51%;
                right: 0;
                top: -49px;
                height: 340px;
                transform: translateX(-50%);
            }
        }
        &-subtitle {
            margin-bottom: 0.5em;
            font-size: em(1.4, $fil-infos__font-size);
        }
        &-title {
            margin-bottom: 1.8em;
            a {
                font-size: em(1.7, $fil-infos__font-size);
                letter-spacing: .2px;
            }
        }
        &-description {
            font-size: em(1.5, $fil-infos__font-size);
            display: inline-block;
            padding: 0 1.5em;
        }
    }
    &__secondary {
        display: block;
        margin: auto;
        padding-bottom: 2.4em;
        .fil-infos__wrap-img {
            margin: 0 auto;
            width: 90%;
            max-width: inherit;
            text-align: left;
            &:before {
                //height: 100%;
                //right: 2px;
                //width: 20px;
                //box-shadow: 0 0 20px;
                //left: inherit;
                //transform: none;
            }
            img {
                margin: inherit;
                width: 100%;
            }
        }
        &-item {
            width: 100%;
            display: block;
            margin-bottom: 2em;
            & + .fil-infos__secondary-item {
                margin-left: 0;
            }
        }
        &-description {
            font-size: em(1.5, $fil-infos__font-size);
            line-height: 1.2;
            padding: 0;
            text-align: center;
        }
        &-title {
            margin: 0;
            width: 100%;
            margin-bottom: 1em;
            &:before {
                display: none;
            }
            &:after {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
            a {
                font-size: 18px;
                text-align: center;
                padding-bottom: .8em;
            }
        }
        &-content {
            width: 100%;
            text-align: left;
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: column;
            padding: 0em 4em 2.5em 3.7em;
            position: relative;
            z-index: 1;
            &:before {
                top: 0;
                left: 50%;
                transform: translateY(-25%) translateX(-50%);

            }
        }
    }
}
