// @name Breadcrumb
.breadcrumb {
  $breadcrumb__font-size: 1.4;
  font-family: $typo-3;
  p {
    font-size: #{$breadcrumb__font-size}em;
    color: $color-2--3;
    .breadcrumb__here {
      color: $color-2--3;
    }
    a {
      font-weight: $light;
      color: $color-3--3;
      @include default-icons-after('\e027', 0 0 0 9px, em(1.2, $breadcrumb__font-size), $color-3--3, em(-.2, 1.2));
      &:last-child {
        &:after {
          color: $color-2--3;
        }
      }
    }
  }
}
