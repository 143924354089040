.bloc-contact{
  &__wrapper{
    display:flex;
    justify-content: space-between;
    padding-right: 1.9em;
  }
  .bloc-left{
    text-align: center;
  }
  &__listitems {
    margin-top: 3.7em;
  }
  .list-infos{
    padding-top: 0;
    margin-bottom: 1.6em;
    &__phone{
      &:before{
        color:$color-1--2;
      }
    }
  }
  .contact {
    .button-2{
      padding: 1.05em 1.28em 1.05em 2.7em;
      &:before{
        left: 18px;
      }
    }
  }
}
