.site-infos {
  flex-wrap: wrap;
  width: 40%;
  .site-infos__wrapper{
    width: 64%;
    padding-top: 3px;
    .button-1{
      margin-top: .3em;
      padding-right: 1.5em;
      padding-left: 1.5em;
    }
  }
  .site-infos__img-wrap {
    position: relative;
    padding-right: 0;
    width: 36%;
    img{
      margin-top: -35px;
      width: 82px;
      height: 126px;
    }
  }
  .block-link{
    width: 100%;
    padding-left: 36%;
    padding-top: 1.5em;
    &__item{
      width: 53px;
      height: 53px;
      & + .block-link__item{
        margin-left: 1%;
      }
    }
  }
}
