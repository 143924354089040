// @name Title 1
// @description Styling for title 1
$title--1__font-size: 4.4;
.title-1 {
  font-family: $typo-3;
  font-size: #{$title--1__font-size}em;
  line-height: 20px;
  font-weight: $normal;
  text-transform: uppercase;
  margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 1.8;
.title-2 {
  font-family: $typo-3;
  font-size: #{$title--2__font-size}em;
  font-weight: $normal;
  text-transform: uppercase;
  margin: em(2.5, $title--2__font-size) 0 em(1.5, $title--2__font-size);
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2.5;
.title-3 {
  font-family: $typo-3;
  font-size: #{$title--3__font-size}em;
  line-height: 20px;
  font-weight: $light;
  text-transform: uppercase;
  color: $color-3--3;
  text-align: center;
  padding-bottom: 0;
  margin-bottom: 0;
  &:before{
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: $color-2--1;
    position: relative;
    top: .4em;
  }
  .wrap {
    position: relative;
    display: inline-block;
    padding: 0;
    background: $color-white;
    &::before {
      content: '//';
      color: $color-2--1;
      position: absolute;
      top: 0;
      left: -3px;
    }
    &::after {
      content: '//';
      color: $color-2--1;
      position: absolute;
      top: 0;
      right: -3px;
    }
    .inner {
      padding: 0 1.05em;
    }
  }
}
