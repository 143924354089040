// @name Social networks
.ddm-menu-service{
  .social-networks{
    margin: 0 1.9em;
    bottom: 2px;
    right: -3px;
    &__search{
      display: none;
    }
  }
}
.home{
  .ddm-menu-service{
    .social-networks{
      margin-right: 2em;
      bottom: 1px;
    }
  }
}
