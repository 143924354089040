// @name list document without buttons
// @description List of document without buttons
.list-document_no-button {
  margin-bottom: 4.8em;
  .list-documents__wrapper{
    display: block;
    padding: 1.8em 0 1.7em;
  }
  .list-document {
    &__listitems {
      display: block;
      padding-left: 0;
      margin-bottom: 0;
      &:first-child{
        margin-right: 0;
        &:after{
          display: none;
        }
      }
    }
    &__item {
      padding: 1.3em 1.6em;
    }
    &__title{
      font-size: 2em;
      margin-bottom: 1.3em;
      &::before{
        top: 0.5em;
      }
      &-file{
        a{
          font-size: .88em;
          font-weight: $normal;
        }
      }
      .wrap{
        &::before{
          left: -3px;
        }
        &::after{
          right: -3px;
        }
        .inner{
          padding: 0 1em;
        }
      }
    }
  }
}
