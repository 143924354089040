.click-roll-holder {
  margin-top: 3.1em;
  .ddm__content {
    .ddm__button {
      background-color: darken($color-3--1, 1.2%);
      &::after {
        background-color: darken($color-3--1, 4.2%);
      }
    }
    &.ddm--active{
      .ddm__button {
        background-color: lighten($color-black, 46.5%);
        &::after {
          background-color: lighten($color-black, 42.6%);
        }
      }
      &.ddm{
        .ddm__sub-level{
          padding: 1.5em 2.6em;
        }
      }
    }
  }
}
