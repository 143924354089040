.home-newsletter{
  padding: 0;
  .home-newsletter__title{
    font-size: 2.6em;
    margin-bottom: .3em;
  }
  &__wrap{
    display: block;
    padding-top: 1.9em;
    & > * {
      width: 100%;
    }
  }
  &__top-block{
    padding: 0 1em;
    margin-bottom: 4.1em;
    .icon-holder {
      width: 11.2em;
      margin-right: 3.1em;
    }
    .description {
      padding-top: 1.8em;
      padding-right: 0;
    }
  }
  .subscribe-form {
    width: 100%;
    .label-text {
      padding: 0 0.7em;
      margin-bottom: 0.5em;
    }
    .form-field {
      padding-left: 23px;
    }
    .button-2 {
      margin-left: 0.85em;
    }
  }
}

