// @name Search box
// @description Search module
.search-box {
  float: right;
  padding: 0;
  form {
    margin: 0;
  }
  label {
    font-family: $typo-3;
    font-size: 1.2em;
    text-transform: uppercase;
    color: darken($color-3--3, 5%);
    white-space: nowrap;
    margin-right: em(0.5, 1.8);
  }

  .search-box__fields-wrapper, .search-box__button-wrapper {
    display: inline-block;
    vertical-align: middle;
  }

  // @name Search Field wrapper
  // @state .search__field-wrapper--in-line - Indicates label and field are side by side.
  .search-box__field-wrapper {
    &.search-box__field-wrapper--in-line {
      display: table;
      width: 100%;
      label {
        display: table-cell;
        vertical-align: middle;
        text-align: right;
        padding-right: 1em;
      }
      input, select {
        width: 300px;
        height: 32px;
        display: table-cell;
        vertical-align: middle;
      }
    }
    input{
      background: $color-white;
      border-bottom: 1px solid darken($color-3--3, 5%);
      border-radius: 3px;
      padding-top: 0;
      padding-bottom: 0;
      margin-right: 10px;
    }
  }

  // Drop down menu
  .ddm {
    &--active{
      position: static;
    }
    > .ddm__sub-level {
      width: 460px;
      top: 0;
      right: -1px;
      text-align: right;
      background: $color-white;
      padding: 4px 15px;
    }
    &__title{
      .button-1{
        background: transparent;
        padding: .2em;
        width: 20px;
        height: 20px;
        &:before {
          content: "\e033";
          font-size: 1.15em;
          color: $color-3--2;
          position: relative;
          left: 0;

        }
        &:hover{
          &:before {
            color: $color-2--3;
          }
        }
      }
    }
  }
  .search-box__button{
    width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
    border: 0;
    background: darken($color-3--3, 5%);
    padding: .5em;
    &:before{
      content: "\e033";
      width: 100%;
      font-size: 1em;
      color: $color-white;
      left: 0;
    }
    &:hover, &:active, &:focus{
      background: $color-black;
    }
  }
}

// @name Search list
.pertinence {
  font-size: 1.2em;
  span {
    font-size: em(1, 1.2);
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .pertinence span {
    font-size: 1.1em;
    font-weight: $bold;
  }
}

meter {
  -webkit-appearance: meter;
  -moz-appearance: meterbar;
  width: em(6, 1.2);
  height: em(1, 1.2);
  border: 1px solid darken($color-3--1, 20%);
  &::-webkit-meter-bar {
    background: darken($color-3--1, 20%);
    height: 100%;
    width: 100%;
  }
  &::-webkit-meter-optimum-value {
    height: 100%;
    background: $color-1--2;
  }
  &:-moz-meter-optimum {
    background: darken($color-3--1, 20%);
    border: none;
  }
  &:-moz-meter-optimum::-moz-meter-bar {
    background: $color-1--2;
  }
  &:-moz-meter-sub-optimum::-moz-meter-bar {
    background: antiquewhite;
  }
  &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
    background: steelblue;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, meter {
    display: none;
  }
}
