.videos{
  margin: 2.5em 0 3.7em;
  .video__title {
    display: none;
  }
  &__item {
    margin: 0 auto 3px;
  }
  &__caption{
    display: block;
  }
}
