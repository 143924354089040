.bloc-annuaire {
  display: block;
  margin-bottom: 3.8em;
  &__title {
    $bloc-contact__title__font-size: 1.6;
    font-family: $typo-1;
    font-weight: $bold;
    font-size: #{$bloc-contact__title__font-size}em;
    color: $color-black;
    a {
      color: $color-black;
      &:hover, &:focus {
        color: $color-1--2;
      }
    }
  }
  &__item {
    padding-right: 2em;
    margin-top: 3.8em;
  }
  .teaser-1 {
    font-family: $typo-1;
    font-size: em(1.4, $bloc-contact__font-size);
    margin: em(0.31538, $bloc-contact__font-size) 0;
  }
  .list-infos {
    margin-top: em(1.5, $bloc-contact__font-size);
    margin-bottom: em(2.7, $bloc-contact__font-size);
    &__item {
      &:before{
        color:$color-1--2;
      }
    }
  }
  .bloc-left {
    text-align: left;
    &__title {
      text-align: center;
    }
  }
  .button-2 {
    padding: 1.05em 2.2em 1.05em 3.1em;
    margin-top: em(1, $button-2__font-size);
    &:before {
      content: "\e041";
    }
  }
  &__wrapper{
    display:flex;
    justify-content: space-between;
  }
}
.section-main__aside{
  > .bloc-annuaire{
    .contact{
      margin-top: em(2.2, $bloc-contact__font-size);
    }
  }
}
