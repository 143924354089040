$fil-infos__font-size: 1;

.fil-infos {
    &__body {
        display: block;
    }
    &__wrap {

    }
    &__title {

        .link-all-dates {

        }
        .title-3 {

            .inner {

            }
            &:before {

            }
            .wrap {
                &:before,
                &:after {

                }
                &:after {

                }
            }
        }
        .link-all-dates {
            &:before {

            }
        }
    }
    &__wrap-img  {
        margin-left: 0;
        margin-right: 0;

        img {
            width: 100%;
        }

        &::before {
            height: 220px !important;
            left: auto !important;
            bottom: auto !important;
            top: 50%;
            transform: translateY(-50%) !important;
            right: 1px;
            box-shadow: 3px 0 15px 0 rgba(0,0,0,.2) !important;
            border-radius: 0 !important;
        }
    }
    &__paramount {
        display: table;
        text-align: center;
        margin: 0 auto 2em;
        width: 100%;
        &-wrap {
            width: inherit;
            display: table-row;
            &-img {
                display: inline-block;
                vertical-align: top;
            }
        }
        &-content {
            width: 1px;
            padding: 0 0 5.5em;
            text-align: center;
            display: table-cell;
            margin-left: 0;
            &:before {
                bottom: 100%;
                left: 51%;
                right: 0;
                top: -49px;
                height: 340px;
                transform: translateX(-50%);
            }
        }
        &-subtitle {
            margin-bottom: 0.5em;
            font-size: em(1.4, $fil-infos__font-size);
        }
        &-title {
            margin-bottom: 1.8em;
            a {
                font-size: em(1.7, $fil-infos__font-size);
                letter-spacing: .2px;
            }
        }
        &-description {
            font-size: em(1.5, $fil-infos__font-size);
            display: inline-block;
            padding: 0 1.5em;
        }
    }
    &__secondary {
        display: block;

        .fil-infos__wrap-img {
            margin-left: auto;
            margin-right: auto;
            max-width: 390px;
            width: 100%;

            &:before {
            }

            img {
                width: 100%;
            }
        }

        &-item {
            width: 100%;
            display: flex;
            & + .fil-infos__secondary-item {
                margin-left: 0;
                margin-top: 3%;
            }
        }

        &-description {
            text-align: left;
        }

        &-title {
            padding-left: 2em;

            &:before {

            }
            &:after {
                position: static;
                transform: translateX(0);
            }
            a {
                text-align: left;
            }
        }

        &-content {
            align-self: center;
            width: calc(100% - 390px);
            text-align: left;

            &:before {
                left: 0;
                top: 50%;
                transform: translateX(-25%) translateY(-50%);
            }
        }
    }
}
