// @name Pager list
.pager {
    width: 100%;
    ul {
        font-family: $typo-3;
        clear: both;
        display: block;
        margin: 3em 0 5em 0;
        padding: 0;
        text-align: center;
        font-weight: $medium;
        font-style: normal;
        text-transform: uppercase;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 em(0, 1.4);
        padding: 0;
        background: none;
        font-size: 1.4em;
        a, span {
            padding: em(1, 1.4) em(1.4, 1.4);
            line-height: 1em;
            display: inline-block;
            vertical-align: middle;
        }
        a {
            color: $color-black;
            background: $color-3--5;
            text-decoration: none;
            transition: all ease .2s;
            &:hover, &:focus {
                color: color-contrast($color-3--3);
                background: $color-3--3;
            }
        }
        span {
            cursor: default;
            color: color-contrast($color-2--3);
            background: $color-2--3;
        }
        &.pager__prev, &.pager__next {
            a, span {
                padding: em(1.35, 1.4) em(3.9, 1.4);
            }
            // Inactive
            span {
                color: color-contrast($color-3--1);
                background: $color-3--1;
            }
        }
        &.pager__prev {
            float: left;
            a, span {
                float: left;
                @include default-icons-before('\e026', 0 em(.5, 1.4) 0 0, em(1.2, 1.4), $color-2--2, baseline);
                &:hover:before, &:focus:before {
                    color: #fff;
                }
            }
            span {
                color: $color-3--2;
                // Inactive
                &:before, &:hover:before, &:focus:before {
                    color: $color-3--2;
                }
            }
        }
        &.pager__next {
            float: right;
            a, span {
                float: right;
                @include default-icons-after('\e027', 0 0 0 em(.5, 1.4), em(1.2, 1.4), $color-2--3, baseline);
                &:hover:after, &:focus:after {
                    color: #fff;
                }
            }
            span {
                color: $color-3--2;
                // Inactive
                &:after, &:hover:after, &:focus:after {
                    color: $color-3--2;
                }
            }
        }
        .pager__post {
             display: inline-block;
             padding: 10px;
         }
    }
}

// @name Pager infinite scroll

@keyframes spin {
    0% {
        transform: rotate(0deg) translateY(-50%);
    }
    100% {
        transform: rotate(-360deg) translateY(-50%);
    }
}

.pager-infinite {
    margin: 2em 0;
    text-align: center;
    // @dependence .button-2
    .pager-infinite__button {
        padding: em(1.6, 1.4) em(4.2, 1.4);
        font-size:13px;
        &:before {
            content: "\e05f";
        }
    }
    .pager-infinite__loading {
        &:before {
            animation: spin 1s infinite linear;
            content: "\e05f";
            transform-origin: top;
        }
    }
}

// @name Pager single
// @dependence .pager
.pager-single {
    .pager-single__back-to-list {
        display: block;
        padding-top: 30px;
        clear: both;
        a {
            background: $color-2--3;
            color: color-contrast($color-2--3);
            font-weight: $bold;
            font-size:13px;
            padding: em(1.4, 1.3) em(3.9, 1.4);
            @include default-icons-before('\e02f', 0 5px 0 0, em(1.2, 1.4), $color-1--2, baseline);
        }
    }
}

// @name Pager single title
// TODO

// @name Pager alphabet
.pager-alphabet {
    margin-bottom: 5em;
}

.pager-alphabet__title {
    font-size: 1.3em;
    font-weight: $bold;
    margin-bottom: em(1.5, 1.4);
}

.pager-alphabet__listitems {
    text-align: left;
    > li {
        display: inline-block;
        vertical-align: middle;
        margin: .2em 0.1em;
        &.pager-alphabet__item--current {
            > a {
                background:$color-2--3;
                color: $color-white;
            }
        }
        > a, > span {
            $pager-alphabet__listitems__a__font-size: 1.4;
            font-size: #{$pager-alphabet__listitems__a__font-size}em;
            font-family:$typo-3;
            background: $color-2--1;
            color: $color-black;
            display: block;
            padding: em(.6, $pager-alphabet__listitems__a__font-size) em(1.1, $pager-alphabet__listitems__a__font-size);
        }
        > a {
            &:hover, &:focus {
                background: $color-2--3;
                color: $color-white;
                text-decoration: none;
            }
        }
        > span {
            background: $color-white;
            border: 1px solid darken($color-3--5, 3%);
            color: darken($color-3--5, 15%);
        }
    }
}

