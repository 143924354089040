.home-publications{
  margin-bottom: 4.4em;
  .home-wrap{
    display: block;
  }
  .home-publications__title{
    max-width: 18.9em;
    margin: 0 auto .75em;
  }
  &__list{
    max-width: 74.8em;
    width: 100%;
    margin: 0 auto 4.4em;
  }
  &__list-large{
    width: 50%;
  }
  &__list-small{
    width: 39%;
  }
}
