.menu-cross{
  width: 100%;
  padding: 0;
  margin-bottom: 2em;
  ul{
    width: 30%;
    min-width: 11.5em;
    margin: 0 auto;
    li{
      padding-right: 0;
    }
  }
}