.newsletter {
    width: 100%;
    text-align: center;
    padding: 0;
    margin-bottom: 3.4em;
    &__fields {
        width: 74%;
        margin: 0 auto;
    }
    &__fields-wrapper {
        width: 100%;
        label {
            margin-right: -40px;
        }
    }
    &__button-wrapper {
        width: 50px;
        .button-1 {
            padding-left: 0;
            padding-right: 0;
            &:before {
                right: auto;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &__title {
        margin-top: 2em;
        margin-bottom: .55em;
    }
    .newsletter__links {
        justify-content: center;
    }
}
