// Newsletter inscription
$newsletter__font-size: 1;

.newsletter {
    width: 29.5%;
    padding: 3px 4.1% 0 5.1%;
    position: relative;
    border-left: 1px solid rgba($color-white, .2);
    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 2.7rem;
        left: -1px;
        background: rgba($color-white, .2);
    }
    &:after {
        top: 100%;
    }
    &:before {
        bottom: 100%;
    }
    h2, label, a {
        color: $color-white;
    }
    &__title {
        font-size: em(1.8, $newsletter__font-size);
        font-weight: $bold;
        font-family: $typo-3;
        text-transform: uppercase;
        margin-bottom: .95em;
    }
    .newsletter__links {
        display: flex;
        li {
            margin-top: .7em;
            &:before {
                display: none;
                font-size: em(1, $newsletter__font-size);
            }
            & + li {
                margin-left: 1.6em;
            }
        }
        a {
            color: $color-white;
            font-size: em(.85, $newsletter__font-size);
            font-family: $typo-3;
            font-weight: $light;
            text-transform: uppercase;
            letter-spacing: .1px;
            &:before {
                content: '/';
                margin-right: 2px;
                color: $color-2--1;
            }
        }
    }
    &__fields {
        display: flex;
        align-items: flex-end;
    }
    &__fields-wrapper {
        width: 80%;
        label {
            font-size: em(1.3, $newsletter__font-size);
            font-weight: $normal;
            margin-bottom: .5em;
        }
        input {
            height: em(4, 1.4);
            background: $color-3--8;
            color: $color-white;
            display: block;
            font-size: em(1.4, $newsletter__font-size);
            line-height: 1;
            box-sizing: border-box;
            padding: .6em 1em 0.9em;
            &::-webkit-input-placeholder {
                color: $color-white;
                font-size: em(1, $newsletter__font-size);
                font-family: $typo-3;
                font-weight: $light;
                opacity: 1;
            }
            &::-moz-placeholder {
                color: $color-white;
                font-size: em(1, $newsletter__font-size);
                font-family: $typo-3;
                font-weight: $light;
                opacity: 1;
            }
            &:-ms-input-placeholder {
                color: $color-white;
                font-size: em(1, $newsletter__font-size);
                font-family: $typo-3;
                font-weight: $light;
                opacity: 1;
            }
        }
    }
    &__button-wrapper {
        width: 20%;
        .button-1 {
            height: em(4, 1.4);
            background: $color-3--8;
            width: 100%;
            border-radius: 0;
            &:before {
                color: $color-2--1;
                font-size: em(1.25, $newsletter__font-size);
            }
        }
    }
    form {
        margin: 0;
    }
    fieldset {
        margin-bottom: .5em;
    }

    .button-2::after {
        display: none !important;
    }
}

.newsletter__fields-wrapper, .newsletter__button-wrapper {
    display: inline-block;
    vertical-align: bottom;
}
