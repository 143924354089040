// @name list document without buttons
// @description List of document without buttons
.list-document_no-button {
  display: block;
  .list-document {
    &__listitems {
      flex-direction: row;
    }
  }
}
