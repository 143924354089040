.home-slider{
  position: relative;
  z-index: 2;
  .buttonStop,
  .prevNextCarrousel1,
  .pagerCarrousel1{
    display: none;
  }
  .item{
    width: 100%;
  }
  .item-wrap{
    position: relative;
  }
  .img-block{
    max-height: 63.3em;
    min-height: 20em;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20.8% 0;
    visibility: visible!important;
  }
}
