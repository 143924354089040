.menu-main-home {
    text-align: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    background: transparent;
    .menu-main__sublink{
        .icon{
            margin-top: 0;
        }
    }
    .menu-main__level-2-holder {
        z-index: 0;
        top: 70%;
    }
    .menu-main__level-1 {
        padding: 0;
        .menu-main__sublink-1{
            &:before{
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                background: black;
                box-shadow: 30px 0 80px 25px $color-black;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }
    }
    .menu-main__level-1 > li > a {
        padding: 0;
    }
    .menu-main__level-1 > li > a::before {
        display: none;
    }
    .menu-main__level-2 {
        & > li {
            display: flex;
            align-items: center;
            & > a {
                padding-top: .3em;
                padding-bottom: .3em;
                display: inline-block;
                margin-right: -30px;
                max-width: 100%;
            }
        }
    }
    .menu-main__level-2-wrap {
        padding-top: 50px;
        position: relative;
        min-height: 40em;
    }
    .menu-main__level-2 {
        min-height:  35em;
    }
    .menu-main__level-2 a {
        padding-left: 30px;
    }
    .menu-main__level-2 a span {
        display: inline-block;
        vertical-align: middle;
    }
    .menu-main__level-4 {
        &.menu-level-content {
            &:after {
                content: "";
                background-repeat: no-repeat;
                background-position: top center;
                background-image: url("../Images/menu/level4-drop.png");
                -webkit-background-size: cover;
                background-size: cover;
                position: absolute;
                top: -131px;
                left: 0;
                height: 131px;
                width: 100%;
            }
        }
    }
    .menu-main__level-3 {
        &.menu-level-content {
            &:after {
                content: "";
                background-repeat: no-repeat;
                background-position: top center;
                background-image: url("../Images/menu/level3-drop.png");
                -webkit-background-size: cover;
                background-size: cover;
                position: absolute;
                top: -131px;
                left: 0;
                height: 131px;
                width: 100%;

            }
        }
        .menu-main__link{
            padding-top: .1em;
            padding-bottom: .1em;
        }
        a {
            &.menu-level-trigger {
                display: inline-block;
                height: 21px;
                top: 2px;
            }
            &:hover,
            &:focus{
                color: $color-1--1;
            }
        }
        > li {
            display: flex;
            align-items: center;
            a {
                margin-right: -30px;
                max-width: 100%;
                &.menu-main__link {
                    display: inline-block;
                    padding-left: 30px;
                }
            }
        }
    }
    .menu-main__level-1 > li > a.active, .menu-main__level-1 > li > a:hover, .menu-main__level-1 > li > a:active, .menu-main__level-1 > li > a:focus {
        background: transparent;
    }
    &.menu-main button.menu-closer {
        top: 100px;
    }
    &__holder {
        position: relative;
        padding-bottom: 15px;
        margin-top: -138px;
        z-index: 3;
        max-width: 1515px;
        margin-left: auto;
        margin-right: auto;

        &::after {
            content: '';
            width: 100%;
            height: 270px;
            background-repeat: no-repeat;
            background-position: top center;
            background-image: url("../Images/menu/menu-disabled-bg.png");
            -webkit-background-size: cover;
            background-size: cover;
            //border-radius: 255vh / 38vh;
            position: absolute;
            left: 0;
            bottom: -120px;
        }
        &.opened {

            &:after {
                background-image: url("../Images/menu/menu-drop-bg.png");
            }
        }
    }
    li {
        margin: 0;
        a {
            display: block;
            position: relative;
            padding: 0;
            &:hover {
                background: transparent;
            }

            &:hover, &:focus {
                text-decoration: none;
                &::after,
                .wrap::after {
                    display: block;
                }
                .wrap {
                    border-color: $color-3--3;
                    background: $color-3--3;
                    box-shadow: inset 0 0 0 1px rgba($color-1--1, .5);
                    .title, .text {
                        color: $color-white;
                    }
                    .text {
                        &:before {
                            color: rgba($color-1--1, .5);
                            background: $color-3--3;
                        }
                        &:after {
                            background: rgba($color-1--1, .5);
                        }
                    }
                    &:before{
                        content: '';
                        width: 44px;
                        height: 44px;
                        background: $color-3--3;
                        position: absolute;
                        bottom: -3px;
                        right: -1px;
                    }
                }
            }
            span {
                display: block;
            }
            .wrap {
                width: 180px;
                height: 180px;
                background: $color-3--1;

                border: 10px solid $color-3--1;
                border-radius: 50%;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                box-shadow: inset 0 0 0 1px $color-white;
                position: relative;
                z-index: 1;
                &::after {
                    display: none;
                    content: '';
                    width: 35px;
                    height: 35px;
                    background: $color-3--3;
                    border: 1px solid;
                    border-color: transparent rgba($color-1--1, .2) rgba($color-1--1, .5) transparent;
                    position: absolute;
                    right: 9px;
                    bottom: 9px;
                }
            }
            .title {
                width: 100%;
                max-height: 200px;
                font-family: $typo-3;
                font-size: 16px;
                color: $color-3--3;
                font-weight: $medium;
                text-align: center;
                line-height: 10px;
                text-transform: uppercase;
                padding: 0 10px;
                margin: 33px 0 6px;
                position: relative;
                z-index: 1;
            }
            .text {
                font-family: $typo-1;
                text-transform: none;
                width: 100%;
                font-size: 13px;
                line-height: 16px;
                font-weight: $light;
                color: $color-3--3;
                position: relative;
                z-index: 1;
                padding: 16px 37px 0;
                &:before, &:after {
                    content: "";
                    speak: none;
                    display: block;
                    position: absolute;
                }
                &:before {
                    content: '||';
                    font-size: 14px;
                    color: $color-3--3;
                    background: $color-3--1;
                    transform: translateX(-50%) skew(-20deg);
                    top: 0;
                    left: 50%;
                    z-index: 1;
                }
                &:after {
                    top: 8px;
                    right: 20px;
                    left: 20px;
                    background: $color-3--2;
                    height: 1px;
                }
            }

            &.active {
                text-decoration: none;

                &::after, .wrap::after {
                    display: block;
                }

                .wrap {
                    border-color: $color-1--1;
                    background: $color-1--1;
                    box-shadow: inset 0 0 0 1px rgba($color-3--15, .5);

                    &::before {
                        background: $color-1--1;
                    }

                    &::after {
                        background: $color-1--1;
                        border-color: transparent rgba($color-3--15, .5) rgba($color-3--15, .5) transparent;
                    }

                    .title, .text {
                        color: $color-3--15;
                    }

                    .text {

                        &:before {
                            color: rgba($color-3--15, .5);
                            background: $color-1--1;
                        }

                        &:after {
                            background: rgba($color-3--15, .5);
                        }
                    }

                    &:before{
                        content: '';
                        width: 44px;
                        height: 44px;
                        //background: $color-3--3;
                        position: absolute;
                        bottom: -3px;
                        right: -1px;
                    }
                }
            }
        }
    }
}

.smartphone-menu {
    display: none;
}
