.bloc-actualite {
    padding: 0 1.4em;
    margin-bottom: 2.4em;
    .list-type-2__picture {
        max-width: 270px;
        min-width: 100%;
    }
    &__listitems {
        margin-top: 2.8em;
    }
    &__item {
        display: block;
    }
    &__wrapper {
        margin: 1.6em 0 0 0;
        text-align: center;
    }
    .teaser-1 {
        margin-bottom: 1em;
    }
    .link-view {
        margin-top: 1.7em;
        text-align: center;
    }
}

.bloc-actualite__item-inside {
    display: block;
}
