.newsletter {
  width: 36%;
  border-left: none;
  padding-left: 3.6%;
  padding-right: 2%;
  &:after,
  &:before{
    display: none;
  }
  .newsletter__links{
    li{
      & + li{
        margin-left: 1.5em;
      }
    }
  }
}
