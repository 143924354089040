// @name bloc news

// @name Bloc news in column
$bloc-actualite__font-size: 1;
.bloc-actualite {
  margin-bottom: 9em;
  &__listitems {
    margin-top: 4.2em;
    display: flex;
    flex-basis: 100%;
    flex-wrap: nowrap;
  }
  &__title {
    $bloc-actualite__title__font-size: 1;
    font-family: $typo-3;
    font-weight: $bold;
    font-size: em(1, $bloc-actualite__title__font-size);
    margin: 0 0 em(1.5, $bloc-actualite__title__font-size);
    a {
      color: $color-2--3;
      text-transform: uppercase;
      font-size: em(2, $bloc-actualite__font-size);
      &:hover, &:focus {
        color: $color-2--4;
      }
    }
  }
  .category {
    position: relative;
    margin-top: em(1.4, $bloc-actualite__font-size);
    span{
      position: relative;
      padding-left: em(1.5, $bloc-actualite__font-size);
      &::before {
        content: '//';
        color: $color-2--1;
        position: absolute;
        top: -3px;
        left: 0;
      }
    }
  }
  .teaser-1 {
    font-size: 14px;
    margin-bottom: 2.2em;
  }
  .list-type-2__picture {
    position: relative;
    overflow: hidden;
    display: block;
    height: auto;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 62.87685%;
    }
    img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-bottom: 1em;
    }
  }
  .button-2 {
    padding: em(0.96429, $bloc-actualite__font-size) em(0.964286, $bloc-actualite__font-size) em(1, $bloc-actualite__font-size) em(2.27143, $bloc-actualite__font-size);
    &:before {
      left: 1em;
      content: '\e027';
    }
    &:hover {
      background: $color-2--2;
    }
  }
}

.section-main__aside {
  > .bloc-actualite {
    .category {
      margin-top: 1.25em;
      margin-bottom: 0.6em;
    }
  }
}

.bloc-actualite__item-inside {
    position: relative;
}
