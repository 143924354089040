// @name Social networks
// @state .home - Customize on the home
.ddm-menu-service {
  .social-networks {
    @include flex-box($flex-direction: row, $justify-content: center, $align-items: center);
    padding: 7px 0;
    margin: 0 5.1em 0 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    > li {
      a {
        max-width: 26px;
        display: block;
        position: relative;
        img {
          display: inline-block;
          vertical-align: middle;
        }
      }
      span {
        display: block;
        padding: 5px;
        -webkit-transition: all .15s ease-in-out;
        transition: all .15s ease-in-out;
      }
      .icon-hover {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        opacity: 0;
      }
    }
    &__title {
      font-family: $typo-3;
      font-weight: $light;
      color: $color-3--7;
      font-size: 15px;
      margin-right: 5px;
      position: relative;
      top: -1px;
    }
    &__search {
      border-left: 1px solid $color-3--2;
      padding-left: 7px;
      margin-left: 6px;
      .ddm__title .button-1::before{
        top: 0;
      }
    }
  }
}

.ddm-menu-service.ddm--active, .home .ddm-menu-service, .ddm-menu-service.hover {
  .social-networks {
    img {
      display: none;
      &:last-child {
        display: inline;
      }
    }
  }
}

.home {
  .ddm-menu-service{
    .social-networks{
      margin-right: 5em;
    }
    &.fixed {
    .social-networks {
      img {
        display: inline;
        &:last-child {
          display: none;
        }
      }
    }
    &.hover {
      .social-networks {
        img {
          display: none;
          &:last-child {
            display: inline;
          }
        }
      }
    }
  }
  }
}
