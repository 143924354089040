.block-link{
  display: flex;
  &__item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height:64px;
    background: $color-3--8;
    border-radius: 50%;
    & + .block-link__item{
      margin-left: 4px;
    }
  }
}