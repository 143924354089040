// Galerie classique (avec vignettes) + Galerie album
.classical-gallery {
  width: 100%;
  max-width: 100%;
  overflow: inherit;
  margin-bottom: 2.5em;
  &-holder{
    margin-right: -10px;
    margin-left: -10px;
  }
}
.carousel-galerie {
  width: 100%;
  position: absolute;
  top: 38%;
  &__thumb{
    display: none;
  }
}
.slider-galerie {
  .slider-galerie__item {
    min-height: 240px;
    background: transparent;
  }
  .infos-img {
    width: 100%;
    max-width: 100%;
    display: block;
    position: relative;
    text-align: right;
    padding: 8px 10px 0;
    &__wrap{
      width: 180px;
      display: inline-block;
      padding-left: 20px;
      padding-bottom: 20px;
      @include default-icons-absolute-before("\e00f", 17px, $color-3--2, 0, inherit, inherit, 6px);
      &::before{
        font-style: normal;
      }
    }
  }
  a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  img {
    max-height: 240px;
    display: inline;
    position: static;
    top: inherit;
    bottom: inherit;
    left: inherit;
    right: inherit;
    transform: inherit;
  }
}
.rte .carousel-galerie__prev,
.rte .carousel-galerie__next,
.carousel-galerie__prev,
.carousel-galerie__next{
  margin: 0 9px;
  button{
    width: 45px;
    height: 45px;
    &::before{
      font-size: 17px;
    }
  }
}
.rte .carousel-galerie__pager{
  width: 100%;
  height: initial;
}
