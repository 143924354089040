// @name Main section heading
// @description Default css
.heading {
    padding: 5em 0 3.3em;
    .subtitle {
        font-size: 1.6em;
    }
    .heading__wrapper {
        padding: 0 10px;
        display: block;
    }
    .heading__figure {
        width: 100%;
        float: none;
        margin-right: 0;
        margin-bottom: 2em;
    }
    h1 {
        font-size: 2.8em;
        color: $color-black;
    }
    // @name picture to right
    &.heading--picture-right {
        .heading__figure {
            margin-left: 0;
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: block;
        vertical-align: inherit;
    }
    .heading__picture {
        img {
            width: auto;
            max-width: 100%;
        }
    }
    .heading__wrapper-date {
        width: 100%;
        border-right: 0;
        display: table;
        border-bottom: 1px solid $color-3--3;
        margin-bottom: 2em;
        .date-1, .hour-place {
            display: table-cell;
            vertical-align: middle;
            width: 50%;
            padding-bottom: 2em;
        }
        .date-1 {
            text-align: left;
            time:nth-of-type(1) {
                margin-left: 0;
            }
        }
    }
    .heading__wrap {
        padding-left: 0;
    }
}
