//.home-web-slider{
//  padding-bottom: 7.1em;
//  margin: 0 1em;
//  .home-web-slider__title{
//    font-size: 2.6em;
//    margin-bottom: 1.5em;
//  }
//  .web-list{
//    width: 200px;
//  }
//  .web-item{
//    &__img{
//      margin-bottom: 1.3em;
//      &::before{
//        width: 90%;
//      }
//      &::after{
//        width: 100%;
//        bottom: -34px;
//      }
//    }
//  }
//  .prevNextCaroussel2{
//    padding: 0 7%;
//    top: 49%;
//  }
//}


.home-web-slider {
    margin: 0 1rem;

    .home-wrap {
        padding: 0 1rem;
    }

    .web-list {
        padding: 0 2.5rem;

        .owl-nav {
            .owl-next {
                right: 5px;
            }
        }
    }
}
