.block-agenda {
    $block-agenda__font-size: 1em;
    margin-top: 2em;
    margin-bottom: 8.4em;
    font-size: $block-agenda__font-size;
    &__head {
        margin: 0 auto 1.6em;
        max-width: 76.6rem;
        text-align: center;
        .home & {
            margin-top: 0;
            margin-bottom: 2.8em;
        }
    }
    &__wrap {
        max-width: 1300px;
        margin: 0 auto;
    }
    .title-3 {
        position: relative;
        margin-bottom: 0.17em;
        font-size: 4em;
        line-height: 1.2;
        color: $color-black;
        &:before {
            position: absolute;
            top: 50%;
            margin: 1px 0 0;
        }
        .wrap {
            &:before, &:after {
                top: 50%;
                transform: translate(0, -50%);
                font-size: 0.85em;
            }
            &:before {
                left: -6px;
            }
            &:after {
                right: -6px;
            }
        }
    }
    &__list {
        display: flex;
    }
    &__item {
        flex: 1 0 0;
        padding: 1em 1em 3.7em;
        background: $color-3--11;
        &:nth-child(2n+2) {
            background: $color-3--12;
            .date,
            .date-1 {
                time {
                    background: $color-3--3;
                    & + span + time {
                        background: $color-2--3;
                    }
                }
            }
        }
    }
    a {
        color: $color-black;
    }
    &__wrapper {
        padding: 2.6em 2em 0 8.8em;
    }

    &__item {
        > a {
            overflow: hidden ;
            display: block;
            height: 22em;

            .picture {
                position: relative;
                display: block;
            }

            img {
                width: 100%;
            }

            &:hover, &:focus {
                opacity: 0.8;
            }
        }
    }


    .wrapper-date {
        position: relative;
    }
    .date,
    .date-1 {
        position: absolute;
        top: -6em;
        left: 1em;
        margin: 0;
        font-family: $typo-3;
        font-size: 1em;
        text-align: center;
        time {
            display: block;
            margin: 0;
            background: $color-2--3;
            width: 6em;
            height: 6em;
            color: $color-1--1;
            & + span + time {
                background: $color-3--3;
            }
            &:before {
                display: none;
                content: "";
            }
        }
        a {
            display: block;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
        .day {
            display: block;
            padding: 0.4em 0 0;
            font-weight: $bold;
            font-size: 2.2em;
            line-height: 1;
        }
        .month {
            display: block;
            margin: -0.1em 0 0;
            font-weight: $light;
            font-size: 1.4em;
            line-height: 1.8em;
            text-transform: uppercase;
        }
    }
    .date {
        top: -2.8em;
    }
    &__category {
        margin: 0 0 0.65em 1px;
        font-weight: $bold;
        font-size: 1.2em;
        line-height: 1.4em;
        text-transform: uppercase;
        @include category-lines($top: -3px, $margin-right: 3px, $color: $color-2--3, $font-size: 0.7em);
    }
    &__title {
        margin: 0 0 0.55em;
        font-weight: $bold;
        font-size: 1.8em;
        line-height: 1.25;
        font-family: $typo-3;
        text-transform: uppercase;
        letter-spacing: -0.01em;
    }
    &__text {
        margin: 0 0 0.79em;
        font-size: 1.4em;
        line-height: 1.25;
        sup {
            font-size: 0.6em;
        }
    }
    .hour-place {
        font-style: italic;
        font-size: 1.3em;
        line-height: 1.25;
        color: $color-2--3;
        text-align: left;
        .place,
        .hour {
            display: inline;
            margin: 0;
            font-weight: $normal;
            font-size: 1em;
            line-height: 1em;
        }
        .place {
            display: block;
        }
        a {
            color: $color-2--3;
        }
    }
    &__focus {
        overflow: hidden;
        padding: 1em;
        background: $color-2--3;
        color: $color-white;
        display: flex;
        a {
            color: $color-white;
        }

        > a {
            //float: left;
            margin: 0 -7.5em 0 0;
            //width: 72.1em;
            //min-width: 72.1em;
            width: 100%;
            height: 38em;
            display: block;
            overflow: hidden;

            .picture {
                height: 100%;
            }

            img {
                position: relative;
                top: 50%;
                width: 100%;
                transform: translate(0, -50%);
            }
        }
        .block-agenda__wrapper {
            //overflow: hidden;
            //padding: 9.8em 7em 0 18.6em;
            overflow-y: auto;
            padding: 1.8em 8em 0 1em;
            max-height: 360px;
            //max-width: 50rem;
            width: 50%;
            align-self: center;
        }
        .wrapper-date {
            //float: left;
            //width: 15em;
            //width: 20em;
            width: 20%;
        }
        .date,
        .date-1 {
            overflow: hidden;
            top: 9.9em;
            left: 0;
            time {
                float: left;
                width: 7.5em;
                height: 7.5em;
            }
            .day {
                font-size: 2.7em;
                line-height: 1.05;
            }
            .month {
                font-size: 1.9em;
                line-height: 1.2;
            }
        }
        .block-agenda__category {
            margin-bottom: 0.57em;
            &:before {
                color: $color-white;
            }
        }
        .block-agenda__title {
            margin-bottom: 0.39em;
            font-size: 2.6em;
            line-height: 1.16;
            letter-spacing: -0.06em;
            sup {
                position: relative;
                top: -0.01em;
                font-size: 0.45em;
            }
        }
        .hour-place {
            color: $color-white;
            a {
                color: $color-white;
            }
        }
    }
}

.home .block-agenda {
    margin-top: 8em;
    position: relative;
    top: -8em;
}


