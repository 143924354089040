// @name Header page
.header {
    @extend .clear-fix;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
}

body:not(.home) {
    .header {
        z-index: 7;
    }
}

.logo-nav-holder {
    position: relative;
}

// @name Header page
.header__top {
    background: $color-white;
    transition: background ease .2s 0.5s;
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 110;
    }
    .header__top__wrapper {
        width: 100%;
        min-height: 41px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
    }
    &.ddm--active, &.hover {
        background: $color-white;
        transition: background ease .2s 0.01s;
    }
}

.home {
    .header__middle {
        color: $color-white;
        &__wrapper {
            position: relative;
            max-width: 1515px;
            margin: 0 auto;
        }
        a {
            color: $color-white;
        }
    }
    .header__middle__go-to-main {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 40px;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            z-index: 2;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            width: 80px;
            height: 80px;
            box-shadow: 0 0 0 2000px $color-white;
        }
        a {
            display: block;
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            > svg {
                fill: $color-white;
                width: 20px;
                height: 20px;
            }
        }
    }
    .menu-wrapper {
        width: 100%;
        height: 0;
        opacity: 0;
        position: relative;
        top: -100%;
        transition: all .2s ease-in-out;
    }

}

@media screen and (min-width: 767px) {
    .fixed-menu {
        .menu-wrapper {
            height: auto;
            opacity: 1;
            transform: translateY(0);
        }
    }
    .fixed-top-nav {
        //z-index: 100;
        .header__top {
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            transition: all .3s ease-in-out;
        }
        .header__top.ddm--active {
            //overflow: auto;
            .header__top__wrapper {
                //top: -15px;
            }
        }
    }
}

// @name Top of content
.top-of-content {
    background: $color-3--4;
}

.top-of-content__wrapper {
    @extend .wrapper-main;
    padding: 2.1em 0 2.1em 6em;
    border-bottom: 1px solid $color-3--2;
    @include flex-box(row, space-between, center);
    &::after {
        display: none;
    }
}

// @name Main wrapper
.main {
    width: 100%;
    clear: both;
    position: relative;
    z-index: 6;
}

.z-index-1 {
    z-index: 1;
}

// @name Main wrapper
.home .main {
    padding-top: 2em;
}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-main;
    padding-top: 6.2em;
    padding-bottom: 3em;
    box-sizing: content-box;
}

.section-main__wrapper-2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

// @name Main section content
.section-main__content {
    width: calc(100% - 35rem);
    flex-grow: 1;
}

// @name Main section aside
.section-main__aside {
    padding-left: 7.9em;
    > .column-bloc {
        *:first-child {
            margin-top: 0;
        }
    }

    &:empty {
        display: none;
        padding: 0;
    }
}

// @name link-all-dates
.link-all-dates {
    font: $light 1.2em/1.25 $typo-3;
    text-transform: uppercase;
    color: $color-black;
    &:before {
        content: "/";
    }
    .home & {
        margin: 0 0.4em 0 1em;
    }
}

// @name Footer
.footer {
    background: $color-3--3;
    margin: 0 4em 0;
    position: relative;
    z-index: 7;

    .footer__wrapper {
        @extend .wrapper-main;
        padding: 2.7em 0 0;
        max-width: 100%;
        position: relative;
    }
    .footer__wrapper-2 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}

.clearfix {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
