.home-newsletter{
  max-width: 74.8em;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto 2em;
  .home-newsletter__title{
    max-width: 13.1em;
    margin: 0 auto .75em;
  }
  &__wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    & > *{
      width: 50%;
    }
  }
  &__top-block{
    .description{
      padding-top: 1.9em;
    }
  }
  .subscribe-form{
    width: 30em;
    form{
      margin: 0;
    }
    fieldset{
      margin: 0;
    }
    label {
      margin-bottom: 0.65em;
    }
  }
}
