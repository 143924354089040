.videos {
  max-width: 100%;
  margin: 3.7em 0 6.5em;
  .video__title {
    margin: 0 0 .7em;
  }
  &__item{
    max-width: 70rem;

  }
}
