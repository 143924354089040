// @name Signature stratis
.stratis {
  background: $color-3--3;
  font-family: $typo-3;
  font-size: 1.2em;
  font-weight: $light;
  text-transform: uppercase;
  color: $color-white;
  width: 100%;
  text-align: right;
  padding-top: 6px;
  span {
    display: none;
  }
  a {
    display: inline-block;
    padding: 3px 13px;
    margin-left: 7px;
    color: $color-white;
    background: $color-3--9;
    text-decoration: none;
    &:hover, &:focus {
      color: $color-3--9;
      background: $color-white;
    }
  }
}
