// import google font
@if variable-exists(import-google-font-1) {
    @import url($import-google-font-1);
}

@if variable-exists(import-google-font-2) {
    @import url($import-google-font-2);
}

// Icon fonts
// create font-face for icon fonts
@include fontFace(icons-default, 'default-icons-webfont', normal, normal);
@include fontFace(icons-project, 'icons', normal, normal);

// @name Body
body {
    font-family: $typo-1;
    font-weight: $normal;
    overflow-x: hidden;
}
.page {
    background: $color-white;
    padding-bottom: 4.5em;
    overflow: hidden;
}

@media (min-width: 1600px){
    .page{
        width:100%;
        margin:0 auto;
    }
}

// hide SVG symbol
.svg-symbol {
    display: none;
}
