body:not(.home) {
    .block-agenda {
        position: relative;
        margin-bottom: 5.9em;
        padding: 0 2em 0.8em;
        &__head {
            margin-bottom: 1.51em;
            max-width: 27rem;
        }
        .title-3 {
            margin-bottom: 0;
            font-size: 2.5em;
            &:before {
                margin-top: -1px;
            }
            .wrap {
                &:before, &:after {
                    font-size: 1em;
                }
                &:before {
                    left: -4px;
                }
                &:after {
                    right: -4px;
                }
            }
        }
        .link-all-dates {
            @include default-icons-absolute-before('\e027', 0.75em, $color-1--2, 58%, inherit, inherit, 3.1em);
            position: absolute;
            z-index: 101;
            bottom: 0;
            left: 31.4em;
            padding: 0.8em 2.2em 0.9em 3.4em;
            background: $color-2--3;
            min-width: 12.9em;
            font: $bold 1.4em/1.2 $typo-3;
            letter-spacing: -0.01em;
            color: $color-white;
            text-transform: uppercase;
            text-decoration: none;
            transition: all ease .3s;
            &:before {
                transform: translateY(-50%);
                transition: all ease .3s;
            }
            &:hover, &:focus {
                text-decoration: none;
                background: $color-3--3;
                color: color-contrast($color-3--3);
            }
        }
        &__list {
            display: block;
            margin: 0 auto;
            width: 83%;
        }
        &__item {
            display: none;
            &:first-child {
                overflow: hidden;
                display: block;
                margin: 0 -4em 0 -1em;
                padding: 1em;
                background: $color-white;
            }
        }
        .picture {
            overflow: hidden;
            float: left;
            margin: 1.2em -6em 0 0;
            width: 27em;
            height: 18em;
            img {
                width: 100%;
                height: auto;
            }
        }
        &__wrapper {
            overflow: hidden;
            z-index: 2;
            padding: 0 1em 0 14.9em;
        }
        .wrapper-date {
            float: left;
            width: 12em;
        }
        .date,
        .date-1 {
            top: 2.3em;
            left: 0;
            time {
                float: left;
            }
        }
        &__title {
            margin-right: 5em;
        }
    }
}

.home {

    .block-agenda {

        &__focus {
            display: block;
        }

        &__head {
            margin-bottom: 2.6em;
            max-width: 54rem;
        }

        .title-3 {
            margin-bottom: 0.34em;
            font-size: 3.5em;
            .wrap {
                &:before, &:after {
                    font-size: 1em;
                }
                &:before {
                    left: -4px;
                }
                &:after {
                    right: -4px;
                }
            }
        }
        &__list {
            flex-wrap: wrap;
        }

        &__item {
            flex: 50% 0 0;
            background: $color-white;
            &:nth-child(4n+2),
            &:nth-child(4n+3) {
                background: $color-3--12;
            }
            .block-agenda__wrapper {
                padding: 2.9em 3em 2.4em 10.8em;
            }
            .date,
            .date-1 {
                top: -7em;
                left: 1.2em;
                time {
                    width: 7.1em;
                    height: 7.1em;
                }
                .day {
                    font-size: 2.7em;
                    line-height: 1;
                }
                .month {
                    font-size: 1.7em;
                    line-height: 1.6;
                }
            }
            .date {
                top: -3.5em;
            }
            .block-agenda__category {
                font-size: 1.3em;
            }
            .block-agenda__title {
                font-size: 2em;
            }
            .block-agenda__text {
                margin-bottom: 0.9em;
                font-size: 1.5em;
            }
            .hour-place {
                font-size: 1.5em;
            }

            > a {
                height: auto;
            }
        }

        &__focus {

            > a {
                position: relative;
                float: none;
                display: block;
                margin: 0;
                //padding-bottom: 56.25%;
                width: 100%;
                height: auto;

                .picture {
                    position: relative;
                    display: block;
                    width: 100%;
                }

                img {
                    position: relative;
                    left: 0;
                    width: 100%;
                    top: 0;
                    transform: none;
                }
            }

            .block-agenda__wrapper {
                overflow: visible;
                padding: 2.8em 2em 2.3em 17.8em;
            }
            .date,
            .date-1 {
                top: -7.5em;
                left: 7em;
                time {
                    float: none;
                }
            }
            .block-agenda__category {
                margin-bottom: 0.9em;
            }
            .block-agenda__title {
                font-size: 2.4em;
                letter-spacing: 0;
            }
            .block-agenda__text {
                margin-bottom: 0.85em;
            }
        }
    }
}
