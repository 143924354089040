.bloc-agenda{
  margin-bottom: 1.8em;
  .list-type-2__picture {
    max-width: 270px;
    min-width: 100%;
  }
  &__listitems{
    display:block;
    margin-top:20px;
  }
  &__item{
    display:block;
  }
  &__wrapper{
    margin: 10px 0 0 120px;
    text-align: left;
  }
  .pic-wrapper{
    .date-1{
      right: auto;
      top: auto;
      bottom: -82px;
      left:50px;
      time{
        float:none;
      }
    }
  }
  .link-view{
    display:none;
  }
  .additional-btn{
    display:block;
    margin-top: 15px;
  }
}
