$image-gallery__font-size: 1em;
.image-gallery {
    font-size: $image-gallery__font-size;
    margin-bottom: 7.9em;
    .head {
        margin: 0 auto 3em;
        max-width: 76.6rem;
        text-align: center;
    }
    .title-3 {
        position: relative;
        margin-bottom: 0.18em;
        font-size: 4em;
        line-height: 1.2;
        color: $color-black;
        &:before {
            position: absolute;
            top: 50%;
            margin: 1px 0 0;
            background: $color-1--2;
        }
        .wrap {
            &:before, &:after {
                top: 50%;
                transform: translate(0, -50%);
                font-size: 0.85em;
                color: $color-1--2;
            }
            &:before {
                left: -6px;
            }
            &:after {
                right: -6px;
            }
        }
    }
    .link-all-albums {
        font: $light 1.2em/1.25 $typo-3;
        text-transform: uppercase;
        color: $color-black;
        &:before {
            content: "/";
        }
    }
    &__list {
        overflow: hidden;
        & > div {
            position: relative;
            background: $color-3--1;
            transition: opacity ease .3s;
            &:nth-child(2n+2) {
                background: $color-3--13;
            }
            &:hover {
                background: $color-2--3;
            }
        }
    }
    &__col-large {
        float: left;
        width: 60%;
        .image-gallery__text-legend {
            font-weight: $bold;
        }
    }
    &__col-small {
        float: left;
        width: 20%;
    }
    &__link {
        position: relative;
        display: block;
        height: 100%;
        color: $color-black;
        &:hover,
        &:focus {
            text-decoration: none;
            .image-gallery__wrap {
                opacity: 1;
                background: rgba($color-2--3, 0.8);
                color: $color-white;
            }
            .image-gallery__text-category {
                &:before {
                    color: $color-white;
                }
            }
            .image-gallery__count-photos {
                color: $color-white;
            }

            .videos__item::after {
                opacity: 0;
            }
        }
        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: "";
        }
    }
    &__wrap-image,
    .videos__item {
        position: relative;
        width: 100%;
        padding-bottom: 75%;
    }
    img,
    .videos__item iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .videos__item {
        margin: 0;

        &::after {
            content: '\e027';
            font-family: 'icons-default';
            position: absolute;
            z-index: 100;
            transition: all ease .3s;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            width: 6rem;
            height: 6rem;
            align-items: center;
            justify-content: center;
            background-color: rgba($color-1--1, .5);
            border-radius: 50%;
            font-size: 1.4rem;
            color: $color-white;
        }
    }
    &__wrap {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        padding: 0 0 1em;
        width: 100%;
        opacity: 0;
        transition: opacity ease .3s;
    }
    &__text {
        position: relative;
        padding: 5.1em 3em 0;
        width: 100%;
        text-align: center;
        &:before {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-60%, 0);
            border-radius: 50%;
            background: $color-white;
            width: 2.7em;
            height: 2.7em;
            font-size: 1.5em;
            line-height: 2.7;
            font-family: 'icons-default';
            color: $color-2--3;
            content: "\e08a";
        }
        span {
            display: block;
        }
    }
    &__text-category {
        text-transform: uppercase;
        font-weight: $bold;
        font-size: 1.239em;
        line-height: 1.25;
        @include category-lines($top: -3px, $margin-right: 0.4em, $margin-left: -0.6em, $color: $color-black, $font-size: 0.8em);
    }
    &__text-legend {
        margin: 0 0 0.65em;
        font-size: 1.6em;
        line-height: 1.25;
    }
    &__count-photos {
        font-style: italic;
        font-size: 1.342em;
        line-height: 1.25;
        color: $color-2--3;
    }
    .images-hidden {
        display: none;
    }
}
