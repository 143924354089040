// @name site informations

$site-info__font-size: 1;
.site-infos {
  display: flex;
  width: 45%;
  .site-infos__img-wrap {
    padding-right: 2em;
    width: 22.1%;
    img {
      margin-top: -48px;
    }
  }
  .site-infos__wrapper {
    padding-top: 6px;
    width: 37%;
    a{
      font-size: em(1.22, $site-info__font-size);
    }
    .button-1{
      margin-top: .8em;
      background: $color-white;
      color: $color-3--7;
      font-size: em(1.4, $site-info__font-size);
      font-weight: $bold;
      line-height: 1;
      padding: 1.3em 1.5em;
      &:before{
        content: '/';
        color: $color-3--7;
        font-size: em(1.2, $site-info__font-size);
        position: static;
        transform: none;
        margin-right: 4px;
      }
    }
  }
  .block-link{
    width: 40.1%;
    padding-left: 2%;
  }
  a, p{
    font-family: $typo-3;
    font-style: normal;
    font-weight: $light;
    letter-spacing: .3px;
    color: $color-white;
  }
  p{
    margin: em(.5, 1.3) 0 em(.8, 1.3);
    text-transform: uppercase;
    font-size: em(1.4, $site-info__font-size);
    strong{
      line-height: 18px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  ul {
    > li {
      margin: 0.2em 0;
    }
  }
  a {
    &:hover, &:focus {
      text-decoration: none;
    }
  }
}
