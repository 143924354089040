// @name Service menu
// @state .home - Customize on the home
.ddm-menu-service {
    .menu-service {
        width: 100%;
        .ddm__title {
            text-align: center;
            padding: 1em 0;
            br {
                display: none;
            }
            button {
                font-family: $typo-3;
                border: none;
                background: $color-white;
                color: $color-3--3;
                font-size: 1.2em;
                text-transform: uppercase;
                font-weight: $bold;
                padding-top: 2px;
                padding-left: 25px;
                margin-left: 10px;
                @include default-icons-absolute-before('\e014', 13px, $color-3--3, 50%, inherit, inherit, 0);
                &:before {
                    border: 1px solid $color-3--3;
                    width: 19px;
                    height: 19px;
                    line-height: 19px;
                    border-radius: 50%;
                    transition: all ease .2s;
                    transform: translateY(-50%);
                }
            }
        }
    }
    > .ddm__sub-level {
        max-width: 1260px;
        box-sizing: content-box;
        position: relative;
        top: inherit;
        right: inherit;
        background-color: $color-white;
        z-index: 11;
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
        transition: max-height ease .5s;
        margin: 0 auto;
        padding: 0 3% 0 4.4%;
    }
    &.ddm--active, &.hover {
        .menu-service {
            .ddm__title {
                button {
                    background: $color-white;
                    color: $color-2--3;
                    &:before {
                        color: $color-2--3;
                        line-height: 17px;
                    }

                    &::after {
                        background-color: $color-white;
                    }
                }
            }
        }
    }
    &.ddm--active {
        .menu-service {
            .ddm__title {
                button {
                    &:before {
                        content: "\e015";
                    }
                }
            }
        }
        > .ddm__sub-level {
            max-height: 500em;
            transition: max-height ease-in 2.5s;
        }
    }
}

.home .header.fixed-top-nav,
.header.fixed-top-nav {
    .menu-service {
        .ddm__title {
            top: inherit;
            text-align: center;
            padding: 0 0 1em 0;

            br {
                display: none;
            }
            //button {
            //    min-width: inherit;
            //    min-height: inherit;
            //    font-family: $typo-3;
            //    border: none;
            //    background: $color-white;
            //    color: $color-3--3;
            //    font-size: 1.2em;
            //    text-transform: uppercase;
            //    font-weight: $bold;
            //    padding: 2px 0 0 25px;
            //    margin-left: 10px;
            //    @include default-icons-absolute-before('\e014', 13px, $color-3--3, 50%, inherit, inherit, 0);
            //    &:before {
            //        border: 1px solid $color-3--3;
            //        width: 19px;
            //        height: 19px;
            //        line-height: 19px;
            //        border-radius: 50%;
            //        transition: all ease .2s;
            //        transform: translateY(-50%);
            //    }
            //}
        }
    }
    .ddm-menu-service.ddm--active {
        .ddm__title {
            //top: 15px;

            button {
                &:before {
                    content: "\e015";
                    line-height: 17px;
                }
            }
        }

        .menu-wrapper {
            //display: none;
        }
    }
}

body:not(.home) .header.fixed-top-nav {
    .menu-service {
        .ddm__title {
            padding-top: 1em;
        }
    }

    .ddm-menu-service.ddm--active {
        .ddm__title {
            margin-top: 1rem;
        }
    }
}

.service {
    @include flex-box(row, space-between, flex-start);
    padding: 2.75em 0 0;
    > * {
        flex: 0 1 25.3333%;
    }
    &__title {
        font-family: $typo-3;
        font-size: 2.5em;
        color: $color-3--3;
        text-transform: uppercase;
        margin-bottom: .6em;
    }
    .col1 {
        flex-basis: 70%;
        flex-grow: 1;
    }
    .col2 {
        margin-left: 30px;
    }
    &__closer {
        @include default-icons-before("\e022", 0, 20px, $color: $color-black);
        position: absolute;
        top: 15px;
        right: 3%;
    }
}

.service__lesplusdemandes {
    &__listitems {
        padding: 20px 0;
        margin-left: -6.5%;
        li {
            display: inline-block;
            vertical-align: top;
            margin: 0 0 2.6% 6.5%;
        }
    }
    &__item {
        display: block;
        text-align: center;
        text-decoration: none;
        &:hover, &:active, &:focus {
            text-decoration: none;
            .service__lesplusdemandes__figure {
                background: $color-1--2;
                .picto {
                    opacity: 0;
                    &-hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &__figure {
        display: block;
        width: 116px;
        height: 116px;
        background: $color-3--3;
        border-radius: 50%;
        position: relative;
        margin: 0 auto 10px;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        span {
            max-width: 57px;
            display: block;
            text-align: center;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            left: 50%;
            right: 0;
            margin: 0 auto;
        }
        .picto {
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
            &-hover {
                opacity: 0;
            }
        }
    }
    &__text {
        font-family: $typo-3;
        font-size: 1.4em;
        color: darken($color-3--3, 3%);
        text-transform: uppercase;
    }
}

.home,.page {
    .header__top__wrapper {
        min-height: 40px;
    }
    .menu-service {
        position: relative;
        .ddm__title {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            padding: 0;
            br {
                display: inherit;
            }
            button {
                text-align: center;
                background: $color-white;
                min-width: 225px;
                min-height: 40px;
                border-radius: 4px / 40px;
                white-space: normal;
                transition: none;
                line-height: 1.2em;
                padding-bottom: 6px;
                padding-left: 0;
                margin-left: 0;
                color: $color-white;
                background-color: $color-2--3;
                font-size: 1.6rem;

                &:before {
                    top: calc(100% - .5rem);
                    bottom: 8px;
                    left: 50%;
                    transform: translateX(-50%);
                    margin: 0;
                    color: inherit;
                    display: block;
                    transition: none;
                    border: 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -2.3rem;
                    width: 6rem;
                    height: 6rem;
                    background-color: $color-2--3;
                    left: 50%;
                    margin-left: -3rem;
                    border-radius: 50%;
                    z-index: -1;
                }
            }
        }
        .ddm__sub-level {
            z-index: 99;
            background: $color-3--5;
        }
    }
    .header__middle {
        margin: 0 4.3em;
    }
}
