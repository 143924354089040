// @name list document without buttons
// @description List of document without buttons
.list-document_no-button {
  display: none;
  margin-bottom: 6em;
  .list-documents__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgba(111, 202, 217, .2);
    padding: 1.8em 0 0;
  }
  .list-document {
    &__listitems {
      display: block;
      position: relative;
      font-size: 1em;
      padding-left: 3.3em;
      margin: 0 0 1.8em;
      &:first-child{
        margin-right: 3.4em;
        &:after{
          content: '';
          width: 1px;
          background: $color-3--2;
          height: 86%;
          position: absolute;
          right: -5%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    &__item {
      width: 100%;
      display: block;
      flex: inherit;
      padding: 1.3em 0;
      margin: 0;
      &:before {
        display: none;
      }
    }
    &__title-file {
      display: flex;
      align-items: center;
      min-height: 40px;
      font-weight: $light;
      font-size: 1.6em;
      color: $color-black;
      text-decoration: none;
      overflow: visible;
      padding-left: 0;
      margin: 0;
      a{
        display: inline;
        vertical-align: baseline;
        line-height: 1.35em;
        color: $color-black;
        &:hover{
          text-decoration: underline;
        }
      }
      &:before {
        font-size: 40px;
        position: relative;
        top: inherit;
        left: inherit;
        color: $color-2--3;
        margin-right: 7px;
      }
    }
    &__file-size {
      margin-top: 0;
      font-size: .75em;
      font-weight: $normal;
      white-space: nowrap;
      margin-left: 3px;
    }
    &__title-file{
      word-break: normal;
    }
    &__title{
      text-align: center;
      font-size: 3.8em;
      color: $color-black;
      margin-bottom: 0.75em;
      &::before{
        background: lighten($color-2--3, 5%);
        top: 0.3em;
      }
      .wrap{
        &::before{
          color: lighten($color-2--3, 5%);
          left: -6px;
        }
        &::after{
          color: lighten($color-2--3, 5%);
          right: -6px;
        }
        .inner{
          padding: 0 0.8em;
        }
      }
    }
  }
}
