.header__wrapper-link {
    display: none;
}

.header {
    padding-top: 0px;
}

.header__top {
    position: relative;
    z-index: 10;

    .social-networks {
        display: none;
    }
    //> .ddm__sub-level,
    //> .header__top__wrapper {
    //    display: none;
    //}
}

.logo-nav-holder {
    position: static;
}

.home {
    .header {
        padding-top: 0px;
    }
    .header__middle {
        background: none;
        margin: 0 1em;
    }
    .header__middle__wrapper {
        display: block;
    }
    .menu-wrapper {
        height: auto;
        opacity: 1;
        top: inherit;
        .logo {
            display: none;
        }
    }
}

// @name Main wrapper
.home .main {
    padding-top: 3.8em;
}

// @name Main section wrappers
.section-main__wrapper {
    width: 100%;
    padding: 3.3em 1em;
}

.top-of-content
.section-main__wrapper-2 {
    display: flex;
    flex-direction: column;
    > * {
        display: block;
        vertical-align: inherit;
    }
}

// @name Footer
.footer {
    margin-left: 1em;
    margin-right: 1em;
    .footer__wrapper {
        padding-top: 0;
    }
    .footer__wrapper-2 {
        margin: 0;
        display: block;
        padding-bottom: 0;
        > * {
            flex: inherit;
        }
    }
}


.page {
    padding-bottom: 1.125em;
}
