// @name Heading single news
// @dependency .heading
.heading--single-news {
  .heading__picture {
    img {
      width: auto;
      max-width: 100%;
    }
  }
}
.heading{
  padding: 6.5em 0 4.2em;
  .heading__wrapper{
    padding: 0 1.9em;
    box-sizing: inherit;
  }
  .subtitle + h1{
    line-height: 1.05em;
  }
}
