.menu-main.menu-main-home li {
    width: auto;
}

.menu-main .overlay-level-1 {
    display: none;
}

.menu-main-home {
    .menu-main__level-1 > li > a::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: black;
        box-shadow: 30px 0 80px 25px #000;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        left: 0;
        margin: 0 auto;
    }
    .menu-main-home li a:hover::after, .menu-main-home li a:hover .wrap::after, .menu-main-home li a:focus::after, .menu-main-home li a:focus .wrap::after, .menu-main-home li a.active::after, .menu-main-home li a.active .wrap::after {
        display: none;
    }
    .menu-main__search {
        display: none;
    }
    .menu-main__level-1 {
        text-align: center;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        background: transparent;
    }
    .menu-main__level-1 > li > a {
        border: 0;
        padding-right: 7px;
    }
    .menu-main__sublink-1::after {
        display: none;
    }
    li {
        margin-bottom: 1em;
        a {
            &::before {
                top: 40%;
            }
            .wrap {
                width: 143px;
                height: 143px;
                background: $color-3--1;
                border-color: $color-3--1;
                box-shadow: inset 0 0 0 1px $color-white;
                &::after {
                    width: 22px;
                    height: 22px;
                    right: 8px;
                }
            }
            .title {
                margin: 26px 0 4px;
            }
            .text {
                &::before {
                    font-size: 12px;
                    background: $color-3--1;
                }
                &::after {
                    right: 11px;
                    left: 11px;
                }
            }
        }
    }
    &__holder {
        overflow: initial;
        max-width: 30em;
        margin: 0 auto;
        display: none;

        &::after {
            display: none;
        }
    }
}

.smartphone {
    &-menu {
        padding-bottom: 4em;
        text-align: center;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding-top: 20px;
        background: transparent;
    }
    &-list {
        border: 0;
        padding-right: 7px;
        text-align: center;
        display: flex;
        overflow: initial;
        max-width: 30em;
        margin: 0 auto;
        flex-wrap: wrap;
        li {
            width: auto;
            display: block;
            margin: 0;
            margin-bottom: 1em;
        }
    }
    &-item {
        display: block;
        position: relative;
        padding-right: 3px;
        span {
            display: block;
        }
        .wrap {
            width: 143px;
            height: 143px;
            border: 10px solid $color-white;
            border-radius: 50%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            box-shadow: inset 0 0 0 1px $color-white;
            position: relative;
            background: $color-3--1;
            border-color: $color-3--1;
            &:before {
                display: none;
                content: '';
                width: 44px;
                height: 44px;
                background: $color-3--3;
                position: absolute;
                bottom: -3px;
                right: -1px;
                z-index: -1;
            }
            &::after {
                display: none;
                content: '';
                width: 25px;
                height: 25px;
                background: $color-3--3;
                border: 1px solid;
                border-color: transparent rgba($color-1--1, .2) rgba($color-1--1, .5) transparent;
                position: absolute;
                right: 8px;
                bottom: 8px;
            }
        }
        .title {
            width: 100%;
            max-height: 200px;
            font-family: $typo-3;
            font-size: 16px;
            color: $color-3--3;
            font-weight: $medium;
            text-align: center;
            line-height: 10px;
            text-transform: uppercase;
            padding: 0 10px;
            margin: 33px 0 6px;
            position: relative;
            z-index: 1;
        }
        .text {
            font-family: $typo-1;
            text-transform: none;
            width: 100%;
            font-size: 12px;
            line-height: 16px;
            font-weight: $light;
            color: $color-3--3;
            position: relative;
            z-index: 1;
            padding: 16px 1.66667em 0;
            &:before, &:after {
                content: "";
                speak: none;
                display: block;
                position: absolute;
            }
            &:before {
                content: '||';
                font-size: 14px;
                color: $color-3--3;
                background: $color-white;
                transform: translateX(-50%) skew(-20deg);
                top: 0;
                left: 50%;
                z-index: 1;
            }
            &:after {
                top: 8px;
                right: 20px;
                left: 20px;
                background: $color-3--2;
                height: 1px;
            }
        }
        &:hover, &:focus {
            text-decoration: none;
            &::after,
            .wrap::after {
                display: block;
            }
            .wrap {
                border-color: $color-3--3;
                background: $color-3--3;
                box-shadow: inset 0 0 0 1px rgba($color-1--1, .5);
                &:before {
                    display: block;
                }
                .title, .text {
                    color: $color-white;
                }
                .text {
                    &:before {
                        color: rgba($color-1--1, .5);
                        background: $color-3--3;
                    }
                    &:after {
                        background: rgba($color-1--1, .5);
                    }
                }
            }
        }
        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background: black;
            box-shadow: 30px 0 80px 25px $color-black;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            left: 0;
            margin: 0 auto;
        }

        &:active {
            text-decoration: none;

            &::before {
                box-shadow: none;
            }

            &::after, .wrap::after {
                display: block;
            }

            .wrap {
                border-color: $color-1--1;
                background: $color-1--1;
                box-shadow: inset 0 0 0 1px rgba($color-3--15, .5);

                &::before {
                    display: block;
                    background: $color-1--1;
                    content: '';
                    width: 44px;
                    height: 44px;
                    position: absolute;
                    bottom: -3px;
                    right: -1px;
                }

                &::after {
                    background: $color-1--1;
                    border-color: transparent rgba($color-3--15, .5) rgba($color-3--15, .5) transparent;
                }

                .title, .text {
                    color: $color-3--15;
                }

                .text {

                    &:before {
                        color: rgba($color-3--15, .5);
                        background: $color-1--1;
                    }

                    &:after {
                        background: rgba($color-3--15, .5);
                    }
                }
            }
        }
    }
}
