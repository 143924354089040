.content-page .logo,
.home .logo-nav-holder .logo{
  width: 92px;
  top: -20px;
  strong{
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 20px;
    margin-bottom: 0;
  }
}
.home{
  .header__middle{
    .logo{
      top: 0;
      margin-top: -36px;
    }
  }
}
