// @name All dates
.all-dates {
    margin-bottom: 3em;
}

$all-dates__title__font-size: 1.8;
.all-dates__title {
    font-family: $typo-2;
    font-size: #{$all-dates__title__font-size}em;
    font-weight: $light;
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: em(1.5, $all-dates__title__font-size);
}

.all-dates__listitems {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -1%;
}

.all-dates__item {
    margin: 0 1% 2em;
    width: 31.3333%;
    display: flex;
    align-items: center;
    position: relative;
    &:before {
        content: "";
        speak: none;
        width: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 7em;
        background: $color-3--3;
    }
    &:last-child {
        &:before {
            background: transparent;
            width: 0;
        }
    }
    .date-1 {
        padding-right: 1em;
    }
    .hour-place {
        padding-left: 1em;
    }
    .button-1 {
        padding-left: 2em;
        padding-right: 1em;
        white-space: normal;
    }
    .ddm--all-dates {
        .ddm__sub-level {
            display: block;
            visibility: hidden;
            top: 100%;
            left: 0;
            right: 0;
            overflow: hidden;
            z-index: 2;

        }
        .ddm__wrapper {
            transform: translateY(-110%);
            transition: all ease .1s;
            background: $color-1--3;
            padding: 1.5em;
        }
        &.ddm--active {
            .ddm__sub-level {
                visibility: visible;
            }
            .ddm__wrapper {
                transform: translateY(0);
                transition: all ease .4s;
            }
        }
    }
    .add-to-calendar__title {
        font-family: $typo-2;
        font-size: 1.4em;
        color: color-contrast($color-1--3);
        font-weight: $bold;
    }
    .add-to-calendar__item {
        font-size: 1.4em;
        color: color-contrast($color-1--3);
        margin: em(.5, 1.4) 0;
        a {
            color: color-contrast($color-1--3);
        }
    }
}
