.map{
  margin: 7em 0 7.3em;
  .map__title{
    margin-bottom: .95em;
  }
  .point__address-info{
    right: 0;
    .cross{
      display: none;
    }
    .point-title{
      color: $color-black;
    }
  }
}
