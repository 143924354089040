$fil-infos__font-size: 1;

.fil-infos {
    background: url('../Images/fil-infos/bg.png');
    padding-top: 4.2em;
    margin-bottom: 6.5em;
    img {
        display: block;
        margin: auto;
        width: auto;
    }
    a {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
    &__title {
        max-width: 69rem;
        text-align: center;
        margin: 0 auto 2.9em;
        .link-all-dates {
            line-height: 1.2;
        }
        .wrap {
            background: transparent;
            &:before,
            &:after {
                top: 50%;
                transform: translate(0, -50%);
                font-size: 0.85em;
            }
            &:before {
                left: -6px;
            }
            &:after {
                right: -6px;
            }
        }
        .title-3 {
            margin-bottom: 0.1em;
            font-size: 4em;
            line-height: 1.2;
            color: $color-black;
            position: relative;
            &:before {
                position: absolute;
                top: 50%;
                margin: 1px 0 0;
            }
            .inner {
                //line-height: 56px;
                background: $color-white url('../Images/fil-infos/bg.png');
                padding: 0 1.05em;

            }
        }
    }
    &__wrap {
        padding-bottom: 2.8em;
    }
    &__wrap-img {
        margin-left: -50%;
        margin-right: -50%;
        text-align: center;
    }
    &__body {
        display: flex;
        flex-wrap: wrap;
    }
    &__paramount {
        display: flex;
        margin-bottom: 3.9em;
        overflow: hidden;
        width: 100%;
        .fil-infos__wrap-img {
            overflow: hidden;
            img {
                margin-bottom: -21px;
            }
        }
        &-wrap {
            width: 59%;
            overflow: hidden;
        }
        &-subtitle {
            font-size: em(1.2, $fil-infos__font-size);
            font-weight: $bold;
            color: $color-black;
            text-transform: uppercase;
            margin-bottom: 1em;
            display: block;
        }
        &-title {
            margin-bottom: 3em;
            a {
                color: $color-2--3;
                font-family: $typo-3;
                font-size: em(2.4, $fil-infos__font-size);
                font-weight: $bold;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }
        &-description {
            font-size: em(1.4, $fil-infos__font-size);
            font-weight: $normal;
            color: $color-black;
            line-height: 1.2;
        }
        &-content {
            background: $color-white;
            width: 41%;
            position: relative;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            padding-top: 2.6em;
            padding-right: 7em;
            z-index: 1;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: -94px;
                height: 100%;
                background: #fff;
                width: 470px;
                transform: scale(1.5);
                border-radius: 50%;
                transform-origin: 0 50%;
                z-index: -1;
            }
        }
    }
    &__secondary {
        display: flex;
        &-item {
            width: 32%;
            overflow: hidden;
            & + .fil-infos__secondary-item {
                margin-left: 3%;
            }
            .fil-infos__wrap-img {
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    height: 20px;
                    position: absolute;
                    bottom: 5px;
                    width: 35%;
                    max-width: 38rem;
                    left: 50%;
                    transform: translateX(-50%);
                    border-radius: 50%;
                    box-shadow: 0 0 20px;
                    z-index: 0;
                }
                img {
                    position: relative;
                    z-index: 1;
                }
            }
        }
        &-content {
            text-align: center;
            position: relative;
            z-index: 1;
            &:before {
                content: '';
                display: block;
                width: 118px;
                height: 118px;
                border-radius: 50%;
                position: absolute;
                top: -25px;
                left: 50%;
                transform: translateX(-50%);
                background: $color-white url('../Images/fil-infos/bg.png');
                z-index: -1;
            }
        }
        &-title {
            position: relative;
            margin: auto;
            margin-bottom: 1em;
            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                width: 40px;
                height: 1px;
                background: rgba($color-2--3, .3);
                transform: translateX(-50%);
            }
            a {
                color: $color-2--3;
                font-size: em(1.2, $fil-infos__font-size);
                font-weight: $bold;
                text-transform: uppercase;
                text-align: center;
                display: block;
                padding: 1.5em 0 1em;
            }
        }
        &-description {
            display: inline-block;
            font-size: em(1.6, $fil-infos__font-size);
            color: $color-black;
            padding: 0 1.5em;
        }
    }
}
