// @name Shame
// @description Because no project is perfect, you can add some code here if you don't know where to add it.

.confirmation {
    .align-right {
        text-align: center;
    }

    .tx-powermail-pi1_confirmation_back.button-1,
    .tx-powermail-pi1_confirmation_submit.button-2 {
        margin: 1rem 0;
    }
} 
