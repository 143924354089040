// @name Go so far
// @description Sections content below the content

.go-so-far {
  display: block;
  padding: 4.5em 0 4.3em;
  margin: 0 2em;
  .go-so-far__title{
    max-width: 54rem;
    margin: 0 auto .6em;
  }
}

.go-so-far.go-so-far_small {
    max-width: 100%;
    width: 100%;
}

.go-so-far-wrapper  {
    padding: 0 2rem;

    .go-so-far.go-so-far_small {
        margin: 0;
    }
}
