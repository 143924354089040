.login-form{
  width: 345px;
  box-sizing: border-box;
  &__wrap{
    background: $color-3--5;
    padding: 40px 33px;
  }
  form, fieldset{
    margin: 0;
  }
  &__field{
    position: relative;
    margin: 0 0 10px;
    .icon{
      position: absolute;
      top: 56%;
      left: 10px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      svg{
        width: 22px;
        height: 21px;
      }
    }
    input{
      height: 3.1em;
      font-size: .91em;
      background: $color-white;
      padding-left: 43px;
      margin: 0;
    }
  }
  .button-row{
    margin-bottom: 25px;
    @include flex-box(row, space-between, center);
    .forgot-pass{
      font-size: 1.4em;
      font-family: $typo-3;
      font-weight: $light;
      color: $color-2--3;
    }
    .button-1{
      background: lighten($color-2--2, 10%);
      padding: 1.07em 1.5em 1.07em 2.9em;
      &:before{
        color: $color-2--3;
      }
      &:hover{
        background: $color-2--3;
        color: $color-white;
        &:before{
          color: $color-white;
        }
      }
    }
  }
  .bottom-block{
    background: $color-2--3;
    color: $color-white;
    padding: 22px 10px 22px 32px;
    margin-left: -33px;
    @include flex-box(row, space-between, center);
    .subtitle{
      font-size: 1.6em;
      text-transform: none;
      color: $color-white;
      margin-bottom: 2px;
    }
    p{
      font-size: 1.2em;
    }
    .button-1{
      width: 4.15em;
      height: 3.36em;
      background: none;
      border-radius: 50%;
      border: 1px solid rgba(255,255,255, .3);
      padding: 0;
      &:before{
        content: "\e017";
        font-size: 1.8em;
        text-align: center;
        color: $color-white;
        left: 0;
        right: 0;
      }
      &:hover{
        background: $color-white;
        border-color: $color-white;
        &:before{
          color: $color-2--3;
        }
      }
    }
  }
}
