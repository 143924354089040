// @name Wrapper with wide 940px
.wrapper-main {
    width: 100%;
}
.home-wrap{
    box-sizing: inherit;
}
.column-bloc{
    width: 73.5%;
    margin: 10px auto 5.3em auto;
    .title-3{
        &:before{
            max-width:265px;
            margin:0 auto;
        }
    }
}
