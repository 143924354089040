.rte{
  p{
    line-height: normal;
    margin: 0.3em 0 0.4em;
    & + .ce-textpic {
      margin-top: 0;
    }
  }
  .table-wrapper {
    margin: 1.9em 0 2.6em 0;
  }
  table{
    caption {
      font-size: 1.4em;
      padding-left: 23px;
      margin-bottom: 0.6em;
      @include default-icons-after("\e00e", 0 0 0 1px, 17px, $color-3--2);
    }
    th, td{
      padding: 12px 14px;
    }
  }
  .encadre {
    font-size: 1.6em;
    line-height: 1.3em;
    padding: 25px 20px 15px;
    margin: 0 10px 1.2em;
  }
  .blockquote-holder{
    margin-bottom: 2em;
  }
  blockquote {

  }
  .column--2, .column--3 {
    margin: 0;
    display: block;
    & > *{
      width: 100%;
      margin: 1.5em 0;
    }
  }
}
.rte h1, .rte .h1, .h1{
  font-size: 2.8em;
}
.rte h2, .rte .h2, .h2{
  font-size: 2em;
  padding-left: 35px;
  margin-bottom: 1.3em;
  &::before{
    left: 13px;
  }
  &::after{
    width: 17px;
    height: 1px;
    top: 0.55em;
  }
}
.rte h3, .rte .h3, .h3{
  font-size: 2em;
  margin: 1.3em 0 0.8em;
  &::before {
    top: 1px;
  }
}
.rte h4, .rte .h4, .h4{
  font-size: 1.6em;
  margin: 1.5em 0 1em;
}
.rte h5, .rte .h5, .h5{
  font-size: 1.5em;
  text-transform: none;
  margin: 1.5em 0 1em;
}

// @name Unordered list
.rte ul, .show-hide__content ul {
  font-size: 1.4em;
  margin: 3.3rem 0 1.5rem 2.5rem;
  > li {
    margin-bottom: 1.3em;
    > ul, > a + ul {
      > li {
        margin: 0;
      }
    }
    > ol, > a + ol {
      > li {
        margin: 0;
      }
    }
  }
}

// @name Ordered list
.rte ol, .show-hide__content ol {
  font-size: 1.4em;
  margin: 1.7rem 0 1.5rem 1.3rem;
  > li {
    padding-left: 0.2em;
    margin-bottom: 1.2em;
    &::before {
      font-size: 1.07em;
    }
    > ul, > a + ul {
      > li {
        margin: 0;
      }
    }
    > ol, > a + ol {
      > li {
        margin: 0;
      }
    }
  }
  & + h3 {
    margin: 2.6em 0 0.75em;
  }
}
