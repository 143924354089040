// @name Go so far
// @description Sections content below the content

.go-so-far {
  padding: 3.5em 0 5.4em;
  margin: 0 1em;
  &__wrapper {
    flex-wrap: wrap;
    > * {
      flex-basis: 100%;
    }
  }
  .list-content-2,
  .rte .list-content-2 {
    padding-right: 10px;
    > li {
      padding-left: .9em;
    }
  }
  .title-2 {
    padding-left: 0.9em;
    margin: 1.8em 0 0.9em;
    font-size: 1.8em;
  }
}

.go-so-far.go-so-far_small {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    padding-top: 3rem;
    padding-bottom: 4rem;

    .go-so-far__title {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 4rem;
    }

    .go-so-far__item {
        width: 100%;
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .list-content-2 {
        padding: 0;
    }
}
