.header__mobile-component {
  display: block;
  padding-top: 0;
  padding-bottom: 0;
}
.page.menu-show{
  #header{
    padding-top: 0;
    .logo{
      width: 0;
      opacity: 0;
      padding-left: 0;
    }
    .title-with-logo{
      width: auto;
      opacity: 1;
    }
  }
  .header__mobile-component{
    background: $color-2--3;
    padding: 10px 11px 10px 10px;
    .burger-btn{
      top: inherit;
    }
  }
}
.home{
  .page.menu-show{
    .header__mobile-component{
      top: 0;
    }
  }
}
