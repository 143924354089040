.top-of-content {
    display: none;
}

.section-main__wrapper {
    box-sizing: inherit;
    padding: 4.2em 2em ;
}

.section-main__wrapper-2 {
    //display: block;
    //> * {
    //    display: block;
    //}
}

.header__middle__wrapper {
    position: relative;
    margin-bottom: 1em;
}

// @name Main wrapper
.home .main {
    padding-top: 1.2em;
}

.footer {
    margin-left: 2em;
    margin-right: 2em;
    position: relative;
    z-index: 7;

    .footer__wrapper {
        padding-top: 1.8em;
    }
}

.section-main__content {
    width: 100%;
}

// @name Main section aside
.section-main__aside {
    padding-left: 0;
}

.page {
    padding-bottom: 2.25em;
}

.fixed-top-nav {
    .header__top {
        position: relative;
    }
}
