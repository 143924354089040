$space-citizen_font: 1;
.space-citizen {
    margin-bottom: 6.6em;
    .title-3 {
        max-width: 87rem;
        font-size: 4em;
        color: $color-black;
        text-align: center;
        margin: 0 auto 1.55em;
        line-height: 1.2;
        position: relative;
        &::before {
            top: 50%;
            position: absolute;
            margin: 1px 0 0;
        }

        .wrap {
            &::before, &::after {
                font-size: .85em;
                top: 50%;
                transform: translateY(-50%);
                //top: inherit;
            }
            &::before {
                left: -6px;
            }
            &::after {
                right: -6px;
            }
            .inner {
                padding: 0 0.9em;
            }
        }
    }
    &__content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4em;
    }
    &__message {
        background: $color-2--3;
        color: $color-white;
        padding: em(1, $space-citizen_font) em(4, $space-citizen_font) em(1, $space-citizen_font) em(1, $space-citizen_font);
        position: relative;
        left: em(-2, $space-citizen_font);
        @include default-icons-absolute-before('\e011', 2em, $color-white, 50%, 10px, inherit, auto);
        &:before {
            border: 1px solid $color-2--4;
            border-radius: 50%;
            transform: translateY(-50%);
        }
    }
    &__image {
        display: block;
        margin-bottom: 2.7em;
    }
    &__publication {
        width: 32em;
    }
    &__citoyen {
        width: 39%;
        flex-grow: 1;
        margin-left: 4rem;

        .space-citizen--title {
            color: $color-3--3;
            margin-top: -3px;
            &:before {
                color: $color-3--3;
            }
        }
        ul {
            margin-bottom: 2.1em;
        }

        li {
            position: relative;
            &:last-child a {
                border-bottom: 1px solid lighten($color-black, 85%);
            }

            a {
                display: block;
                border-top: 1px solid lighten($color-black, 85%);
                font-family: $typo-3;
                font-size: 1.8em;
                font-weight: $light;
                color: $color-black;
                padding: 0.68em 70px 0.68em 51px;
                @include default-icons-absolute-before('\e011', 1.6em, rgba($color-3--3, .8), 50%, 30px, inherit, auto);
                &:before {
                    border: 1px solid transparent;
                    border-radius: 50%;
                    transform: translateY(-50%);
                }
                &:hover {
                    text-decoration: none;
                    background: rgba($color-1--2, .2);
                    &:before {
                        border-color: $color-1--2;
                        color: $color-1--2;
                    }
                    svg {
                        fill: $color-2--3;
                    }
                }
            }
        }
        .link-all-dates {
            margin-left: .3em;
            &::before {
                margin-right: 2px;
            }
        }
    }
    .category {
        font-size: 1.4em;
        display: block;
        color: $color-3--3;
        border-bottom: 1px solid lighten($color-3--2, 5%);
        padding-bottom: .75em;
        margin-bottom: 0.7em;
        &:hover {
            text-decoration: none;
            color: $color-2--3;
        }
    }
    .teaser-1 {
        font-size: 1.8em;
        line-height: 1.25em;
        padding-right: 7%;
        margin: 0;
        a {
            color: $color-3--3;
        }
    }
    &__form {
        margin-left: 4rem;

        .login-form__wrap {
            padding: 36px 33px 40px;
        }
        .bloc-form--title {
            margin-top: 0;
            margin-bottom: 0.7em;
        }
        .button-row {
            margin-top: 1.3em;
            margin-bottom: 2.4em;
        }
        .bottom-block {
            padding: 19px 10px 23px 32px;
            .subtitle {
                margin-bottom: 0.3em;
            }
        }
    }
}

.space-citizen {
    $this: &;

    &__icons {
        position: absolute;
        top: 50%;
        left: 17px;
        transform: translateY(-50%);

        img {
            width: 20px;
            height: 20px;
            display: block;
        }

    }

    &__icon-hover img {
        display: none;
    }

    &__citoyen li a:hover {
        #{$this}__icons > img {
            display: none;
        }

        #{$this}__icon-hover img {
            display: block;
        }
    }
}
