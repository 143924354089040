.home-web-slider{
  $bg-color: darken($color-3--4, 0.7%);
  background: $bg-color;
  position: relative;
  z-index: 1;
  padding: 5.7em 0 5.1em;
  margin: 0 4em;

  .home-web-slider__title{
    max-width: 16.2em;
    font-size: 3em;
    color: $color-3--3;
    margin: 0 auto .9em;
    &::before{
      top: 0.4em;
    }
    .wrap {
      &::before {
        left: -6px;
      }
      &::after {
        right: -5px;
      }
      .inner {
        background: $bg-color;
        padding: 0 1.1em;
      }
    }
  }
  .home-wrap{
    position: relative;
    box-sizing: inherit;
    padding: 0 6em;
  }
  .web-list{
    //width: 1140px;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    display:block;
    .owl-nav, .owl-nav.disabled {
      height: 0;
      top: 30%;
      width: 100%;
      display: block !important;
      position: absolute;
      .owl-prev, .owl-next{
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.disabled {
          background: lighten($color-3--3, 15%);
        }
        &::before{
          line-height: normal;
        }
        &:hover{
          background: none;
        }
      }
      .owl-prev {
        left: -40px;
        float: left;
        @include default-icons-absolute-before('\e016', 42px, $color-3--7, 0, inherit, inherit, 0);
      }
      .owl-next {
        right: -30px;
        float: right;
        @include default-icons-absolute-before('\e017', 42px, $color-3--7, 0, inherit, inherit, 0);
      }
    }
  }
  .web-item{
    padding-top: 5px;
    img{
      max-width: 100%;
      display: inline-block;
    }
    &__img{
      display: block;
      margin-bottom: 2.1em;
      position: relative;
      &::before, &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
      }
      &::before{
        width: 77%;
        height: 15px;
        background: rgba($color-3--3, .2);
        bottom: -15px;
      }
      &::after{
        width: 90%;
        height: 33px;
        border-radius: 78%/80%;
        background: $bg-color;
        box-shadow: 0 0 3px 0 $color-3--4;
        bottom: -35px;
      }
      &:hover{
        outline: none;
        img{
          box-shadow: 0 0 6px 2px darken($color-3--4, 5%);
        }
      }
      &:focus{
        outline: none;
      }
    }
    &__site{
      display: inline-block;
      font-size: 1.4em;
      font-family: $typo-3;
      color: $color-2--3;
    }
  }
  .prevNextCaroussel2{
    width: 100%;
    height: 0;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 46%;
    left: 0;
  }
}
