.home-publications{
  max-width: 30em;
  margin: 3em auto 4em;
  .home-wrap{
    padding: 0;
  }
  .home-publications__title{
    font-size: 2.6em;
    margin-bottom: .3em;
  }
  .link-all-dates-holder {
    margin-bottom: 2.2em;
  }
  &__list-wrap{
    display: block;
    & > *{
      width: 100%;
    }
  }
  &__list {
    margin-bottom: 2.7em;
  }
  &__list-large{
    margin-bottom: 3.8em;
    .list-type-2__picture{
      width: 100%;
      float: none;
      margin-right: 0;
      margin-bottom: 1.5em;
      img{
        width: 100%;
      }
    }
    .bloc-telechargement__wrapper{
      margin-left: 24%;
      margin-top: 0;
    }
    .bloc-telechargement__title{
      line-height: 2.8em;
      margin: 0;
      a{
        font-size: 2.5em;
      }
    }
    .teaser-1{
      margin: 0;
    }
  }
  .buttons__wrap {
    margin-top: 2.2em;
  }
  &__list-small{
    .list-type-2__picture{
      width: 12.4em;
      margin-right: 20px;
      img{
        width: 100%;
      }
    }
    .bloc-telechargement{
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 1em;
      }
      &__title{
        line-height: 2.2em;
        a{
          font-size: 1.5em;
        }
      }
      &__wrapper{
        margin-top: 2.9em;
      }
    }
    .buttons__wrap {
      margin-top: 1.7em;
    }
  }
}
