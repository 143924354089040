// @name Main menu
$menu-main-size: 1;
.menu-main {
    width: 100%;
    background: transparent url(../Images/bg/header-bg.png) repeat;
    ul {
        text-align: center;
    }
    a {
        font-family: $typo-3;
        font-size: em(2, $menu-main-size);
        font-weight: $light;
        color: $color-3--3;
        display: block;
        padding: 1rem 1rem;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 .5rem;
    }
    &__level-1 {
        padding: em(1.9, $menu-main-size) em(1.7, $menu-main-size) em(1.9, $menu-main-size) 3.2%;
        position: relative;
        ul {
            height: 100%;
            text-align: left;
            li {
                display: flex;
                align-items: center;
                overflow: hidden;
            }
        }
        & > li {

            &.active {
                & > a {
                    background: $color-2--3;
                    color: $color-white;
                    text-decoration: none;
                }
            }

            & > a {
                text-transform: uppercase;
                position: relative;
                padding: 8px 30px;
                &:before {
                    content: '';
                    width: 1px;
                    height: .85em;
                    background: $color-2--3;
                    position: absolute;
                    top: 52%;
                    left: -7px;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
                &.active, &:hover,
                &:active, &:focus {
                    background: $color-2--3;
                    color: $color-white;
                    text-decoration: none;
                }
            }
            &:first-child {
                & > a:before {
                    display: none;
                }
            }
        }
    }
    &__sublink-1 {
        @include default-icons-absolute-after("\e00d", 1.4em, $color-black, 50%, 25px, inherit, inherit);
        z-index: 3;
        &:after {
            display: none;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
    &__level-2-holder {
        display: none;
        min-height: 40em;
        background: $color-2--3;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 2;
        margin-top: -1px;
    }
    &__level-2-wrap {
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
    }
    &__level-2 {
        width: 33.2%;
        position: relative;
        padding: 20px;
        a {
            font-weight: $light;
            span {
                display: inline-block;
                vertical-align: middle;
            }
        }
        .menu-main__sublink {
            .icon {
                width: 19px;
                height: 19px;
                text-align: center;
                border-radius: 50%;
                background: $color-white;
                margin-right: 9px;
                @include default-icons-absolute-before("\e08a", 16px, $color-2--3, 50%, 0, inherit, 1px);
                position: absolute;
                top: 56%;
                left: 1px;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                &:before {
                    margin: 0 auto;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }
        }
        & > li {
            & > a {
                font-size: 1.6em;
                color: $color-white;
                text-transform: uppercase;
                padding-top: .3em;
                padding-bottom: .3em;
                position: relative;
                display: inline-block;
                margin-right: -22px;
                &.menu-main__link {
                    margin-right: 0;
                    padding-left: 35px;
                }
                &.menu-level-trigger {
                    height: 21px;
                }
                &:hover, &:active, &:focus,
                &.active {
                    color: $color-1--1;
                    text-decoration: none;
                    .icon {
                        background: $color-1--1;
                    }
                }
                &.active {
                    .icon {
                        &:before {
                            content: "\e023";
                        }
                    }
                }
            }
        }
    }
    &__level-3,
    &__level-4 {
        display: none;
        width: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        background: $color-3--3;
        padding: 17px 24px;
        &:before {
            content: '';
            display: block;
            border: 28px solid;
            border-color: transparent transparent transparent $color-2--3;
            position: absolute;
            top: 47%;
            left: 0;
        }
        .menu-main__sublink {
            .icon {
                background: $color-1--1;
                top: 49%;
                margin-top: 3px;
                &:before {
                    color: $color-3--3;
                }
            }
        }
        & > li {
            & > a {
                font-size: 1.8em;
                font-weight: $light;
                color: $color-1--1;
                padding-top: .3em;
                padding-bottom: .3em;
                position: relative;
                display: inline-block;
                margin-right: -20px;
                &:hover, &:active, &:focus,
                &.active {
                    color: $color-white;
                    text-decoration: none;
                    .icon {
                        background: $color-white;
                    }
                }
                &.active {
                    .icon {
                        &:before {
                            content: "\e023";
                        }
                    }
                }
                &.menu-main__link {
                    padding-left: 40px;
                }
            }
        }
    }

    &__level-4 {
        background: $color-3--8;
        padding: 22px 24px;
        &:before {
            border-color: transparent transparent transparent $color-3--3;
        }
        .menu-main__sublink {
            .icon {
                background: transparent;
                margin-top: 0;
                &:before {
                    color: $color-white;
                }
            }
        }
        & > li {
            & > a {
                font-size: 1.6em;
                font-family: $typo-1;
                color: $color-white;
                padding-top: .35em;
                padding-bottom: .35em;
                padding-left: 25px;
                &:hover, &:active, &:focus,
                &.active {
                    color: $color-1--1;
                    .icon {
                        background: transparent;
                        &:before {
                            color: $color-1--1;
                        }
                    }
                }
                &.active {
                    .icon {
                        &:before {
                            content: "\e023";
                        }
                    }
                }
            }
        }
    }
    .menu-main__search {
        display: none;
        border-top: 1px solid darken($color-3--3, 5%);
        .search-box {
            float: none;
            padding: 5em 10px;
            &__input {
                width: 220px;
                height: 50px;
                display: inline-block;
                vertical-align: middle;
                border: 1px solid darken($color-3--3, 5%);
                margin-right: 10px;
            }
            &__button {
                width: 50px;
                height: 50px;
                display: inline-block;
                vertical-align: middle;
                border-radius: 50%;
                background: $color-3--3;
                &:before {
                    width: 100%;
                    font-size: 1.2em;
                    color: $color-white;
                    left: 0;
                }
            }
        }
    }
    button.menu-closer {
        width: 40px;
        height: 40px;
        background: $color-white;
        border-radius: 50%;
        @include default-icons-absolute-before("\e022", 21px, $color-2--3, 50%, 0, inherit, 0);
        position: absolute;
        top: 20px;
        right: 1.5%;
        &:before {
            margin: 0 auto;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
    .overlay-level-2 {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }
}

.menu-level-trigger {
    z-index: 5;
}

@media screen and (min-width: 768px) {
    .menu-main {
        overflow: inherit !important;
        display: block !important;
    }
}
