$bloc-agenda__font-size: 1;
.bloc-agenda {
    display: none;
    &__listitems {
        margin-top: 25px;
        display: flex;
        flex-basis: 100%;
        flex-wrap: nowrap;
    }
    &__title {
        $bloc-agenda__title__font-size: 1;
        font-family: $typo-3;
        font-weight: $bold;
        font-size: em(1, $bloc-agenda__title__font-size);
        margin: 0 0 em(1.5, $bloc-agenda__title__font-size);
        a {
            color: $color-black;
            text-transform: uppercase;
            font-size: em(2, $bloc-agenda__font-size);
            &:hover, &:focus {
                color: $color-2--4;
            }
            &:before {

            }
        }
    }
    .category {
        position: relative;
        margin-top: em(1.4, $bloc-agenda__font-size);
        span {
            position: relative;
            padding-left: em(1.5, $bloc-agenda__font-size);
            &::before {
                content: '//';
                color: $color-2--1;
                position: absolute;
                top: -3px;
                left: 0;
            }
        }
    }
    .teaser-1 {
        font-size: 14px;
    }
    .list-type-2__picture {
        position: relative;
        overflow: hidden;
        display: block;
        height: auto;
        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: 62.87685%;
        }
        img {
            height: auto;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            margin-bottom: 1em;
        }
    }
    .button-2 {
        padding: em(0.96429, $bloc-agenda__font-size) em(0.964286, $bloc-agenda__font-size) em(1, $bloc-agenda__font-size) em(2.27143, $bloc-agenda__font-size);
        &:before {
            left: 1em;
            content: '\e027';
        }
        &:hover {
            background: $color-2--2;
        }
    }
    .additional-btn {
        display: none;
        text-align: center;
    }
}

.section-main__aside {
    > .bloc-agenda {
        .category {
            margin-top: em(1.4, $bloc-agenda__font-size);
        }
    }
}
