.space-citizen {
    max-width: 30em;
    margin: 0 auto 3.2em;
    .home-wrap {
        padding: 0;
    }
    .title-3 {
        font-size: 3.2rem;
        text-align: center;
        margin-bottom: 0.75em;

        &::before {
            top: 20px;
            margin: 0;
            transform: none;
        }

        .wrap {
            max-width: 22rem;

            &::before, &::after {
                top: 3px;
                transform: none;
            }

            .inner {
                padding: 0;
            }
        }

    }
    //    &::before {
    //        top: 0.65em;
    //    }
    //    .wrap {
    //        width: 72%;
    //        &::before, &::after {
    //            bottom: inherit;
    //            top: 0.35em;
    //        }
    //        .inner {
    //            line-height: 1.2em;
    //            display: inline-block;
    //            padding: 0;
    //        }
    //    }
    //}
    &__content {
        margin-bottom: 6.5em;
    }
    &__publication {
        width: 100%;
        display: block;
        margin-bottom: 4.9em;
    }
    &__image {
        width: 100%;
        margin-bottom: 1.7em;
    }
    &__wrapper {
        width: 100%;
        padding: 0 1em;
    }
    .teaser-1 {
        font-size: 1.6em;
        line-height: 1.2em;
        padding-right: 0;
    }
    &__citoyen {
        width: 100%;
        margin-right: 0;
        margin-bottom: 3.6em;
        .link-all-dates {
            margin-left: 1.1em;
        }
        .space-citizen--title {
            padding-bottom: 0;
            margin-left: 12px;
        }
        ul {
            margin-bottom: .8em;
        }
        li a {
            line-height: 1.3em;
            padding-left: 62px;
            &::before {
                right: 10px;
            }
        }
    }
    &__form {
        width: 100%;
        padding-top: 0;
        .login-form__wrap {
            padding: 36px 10px 40px;
        }
        .bottom-block {
            margin: 0 -10px;
            .button-1 {
                width: 4.38em;
            }
        }
    }
}
