// @name bloc contact

// @name Bloc contact in column
$bloc-contact__font-size:1;
.bloc-contact {
    margin-bottom: 8.8em;
    &__title {
        $bloc-contact__title__font-size: 1.6;
        font-family: $typo-1;
        font-weight: $bold;
        font-size: #{$bloc-contact__title__font-size}em;
        color: $color-black;
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }
    &__listitems{
        margin-top: 3.4em;
    }
    .teaser-1{
        font-family: $typo-1;
        font-size:em(1.4, $bloc-contact__font-size);
        margin: 0;
        line-height: 1.5em;
    }
    .list-infos{
        font-size: 1.15em;
        padding-top: em(1.2, $bloc-contact__font-size);
        margin-bottom:em(2.1, $bloc-contact__font-size);
        &__phone{
            a{
                text-decoration:none;
            }
        }
    }
    .contact{
        .button-2{
            padding: em(1.1, $bloc-contact__font-size) em(2.28, $bloc-contact__font-size) em(1.1, $bloc-contact__font-size) em(3, $bloc-contact__font-size);
        }
    }
    .list-infos__wrapper {
        display: block;
        flex-wrap: inherit;
        justify-content: inherit;
        > * {
            flex: inherit;
        }
        .list-infos__listitems + .list-infos__listitems {
            border-left: 0;
            padding-left: 0;
            margin-left: 0;
        }
    }
    .button-2 {
        margin-top: em(1, $button-2__font-size);
        &:before {
            content: "\e02a";
        }
    }
}
