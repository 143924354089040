.bloc-projects{
  margin-bottom: 5em;
  &__head{
    margin-bottom: 2.2em;
    max-width: 54rem;
  }
  .title-3{
    margin-bottom: 0.29em;
    font-size: 3.5em;
    .wrap{
      &:before, &:after{
        font-size: 1em;
      }
      &:before{
        left: -4px;
      }
      &:after{
        right: -4px;
      }
    }
  }
  &__text{
    left: 5.2em;
    transform: translateY(-50.6%);
  }
}
