.home-publications{
  margin-bottom: 3em;
  position: relative;
  z-index: 1;
  .home-wrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .home-publications__title{
    text-align: center;
    font-size: 3em;
    color: $color-3--3;
    margin-bottom: 0.75em;
    &::before{
      background: lighten($color-1--2, 5%);
      top: 0.4em;
    }
    .wrap {
      &::before {
        color: lighten($color-1--2, 5%);
        left: -6px;
      }
      &::after {
        color: lighten($color-1--2, 5%);
        right: -5px;
      }
      .inner {
        padding: 0 1.1em;
      }
    }
  }
  .link-all-dates-holder{
    text-align: center;
    margin-bottom: 2.6em;
  }
  &__list{
    width: 800px;
    &-wrap{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      & > *{
        width: 43.3%;
      }
    }
  }
  .list-type-2__picture{
    margin-right: 20px;
    img{
      display: block;
      border: 1px solid lighten($color-black, 85%);
    }
  }
  &__list-large{
    .list-type-2__picture{
      width: 19.5em;
    }
    .bloc-telechargement{
      &__wrapper{
        margin-top: -7px;
      }
    }
    .feuilleter__wrap {
      margin-bottom: 0.9em;
    }
    .teaser-1{
      margin-bottom: 2.8em;
    }
  }
  &__list-small{
    .list-type-2__picture{
      width: 9.1em;
      margin-right: 12px;
    }
    .bloc-telechargement{
      width: 24em;
      &:not(:last-child){
        margin-bottom: 1.2em;
      }
      &__wrapper{
        margin-top: -6px;
      }
      .telecharger,
      .feuilleter{
        padding-left: 0;
        &::before{
          content: '/';
          color: $color-black;
          position: relative;
          top: inherit;
          display: inline-block;
          vertical-align: baseline;
          margin-right: 2px;
        }
      }
      &__title{
        margin-bottom: .6em;
        a{
          font-size: 1.5em;
        }
      }
      .feuilleter__wrap {
        margin-bottom: 0.2em;
      }
    }
  }
  .buttons__wrap{
    margin-top: 2em;
  }
  .bloc-telechargement{
    display: block;
    overflow: hidden;
    &__title{
      line-height: normal;
    }
    &__wrapper{
      overflow: hidden;
    }
  }
}
