// @name bloc publications

// @name Bloc publications in column and in go-so-far
.bloc-telechargement {
    $bloc-telechargement__title__font-size: 1;
    &__title {
        font-family: $typo-3;
        font-weight: $bold;
        letter-spacing: -0.5px;
        font-size: em(1, $bloc-telechargement__title__font-size);
        margin: 0 0 em(0.7, $bloc-telechargement__title__font-size);
        word-wrap: break-word;
        a {
            color: $color-2--3;
            text-transform: uppercase;
            font-size: em(2, $bloc-actualite__font-size);
            &:hover, &:focus {
                color: $color-2--4;
            }
        }
    }
    &__listitems {
        margin-top: 2.9em;
    }

    &__item {

        &:not(:last-of-type) {
            margin-bottom: 2rem;
        }

        &::after {
            content: ' ';
            display: table;
            clear: both;
        }
    }

    .teaser-1 {
        font-family: $typo-1;
        font-size: em(1.6, $bloc-telechargement__title__font-size);
        margin: em(0.31538, $bloc-telechargement__title__font-size) 0 em(1, $bloc-telechargement__title__font-size) 0;
        word-wrap: break-word;
    }
    .list-type-2__picture {
        width: 12em;
        float: left;
        margin-right: 15px;
    }
    .link-view {
        margin-top: 3rem ;
        &__feature {
            margin: 0 0 0.2em 0;
            &:first-child {
                a {
                }
                span {
                    font-weight: $light;
                }
            }
            &.kiosque {
                margin-top: 18px;
            }
        }
        .button-2 {
            padding: em(1, $bloc-telechargement__title__font-size) em(0.4, $bloc-telechargement__title__font-size) em(1, $bloc-telechargement__title__font-size) em(1.5, $bloc-telechargement__title__font-size);
            &:before {
                left: em(0.3, $bloc-telechargement__title__font-size);
                content: '\e027';
            }
        }
    }
    .feuilleter__wrap {
        margin-bottom: em(0.8, $bloc-telechargement__title__font-size);
    }
    .telecharger,
    .feuilleter {
        font-family: $typo-3;
        font-weight: $bold;
        color: $color-3--3;
        text-transform: uppercase;
        padding-left: 18px;
        font-size: em(1.2, $bloc-telechargement__title__font-size);
        @include default-icons-absolute-before('\e06e', em(1.2, $bloc-telechargement__title__font-size), $color-2--2, 3px, inherit, inherit, 0);
    }
    .telecharger {
        @include default-icons-absolute-before('\e064', em(1.2, $bloc-telechargement__title__font-size), $color-2--2, 3px, inherit, inherit, 0);
        span {
            font-weight: $normal;
        }
    }
}
