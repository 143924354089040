// @name Cross menu
$cross-menu__font-size: 1;
.menu-cross {
  text-transform: uppercase;
  width: 25.5%;
  position: relative;
  padding: 1px 1.5% 0 4.2%;
  border-left: 1px solid rgba($color-white, .2);
  &:after,
  &:before{
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 2.7rem;
    left: -1px;
    background: rgba($color-white, .2);
  }
  &:after{
    top: 100%;
  }
  &:before{
    bottom: 100%;
  }
  li {
    vertical-align: top;
    display: block;
    padding: 0 1.8em;
    @include default-icons-absolute-before('\e011', 2em, $color-2--1, 50%, inherit, inherit, 0);
    &:before{
      transform: translateY(-50%);
    }
    a {
      display: block;
      color: $color-white;
      text-decoration: none;
      font-family: $typo-3;
      font-size: em(1.3, $cross-menu__font-size);
      font-weight: $light;
      text-transform: uppercase;
      line-height: 24px;
      &:hover, &:focus {
        color: $color-1--2;
        span {
          text-decoration: underline;
        }
      }
    }
    &.active a {
      color: $color-1--2;
      text-decoration: none;
      font-weight: $bold;
    }
  }
}
