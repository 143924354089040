.logo {
  width: 160px;
  font-size: 1.3em;
  position: absolute;
  top: -36px;
  left: 42px;
  z-index: 10;
  strong{
    display: block;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.2px;
    color: #28a8d9;
    margin-bottom: 3px;
  }
  a, span {
    display: block;
  }
  a:hover{
    text-decoration: none;
    opacity: .9;
  }
  img{
    width: 100%;
  }
}
.home{
  .header__middle{
    .logo{
      top: -36px;
      left: 0;
    }
  }
}

.popin {

    .logo {
        left: 1px;
        top: -2px;

        strong {
            font-size: 12px;
            line-height: 15px;
        }

        img {
            width: auto;
            height: 55px;
        }
    }

    .heading {
        padding-top: 8rem;
    }

}

@media screen and (max-width: 1490px){
  .content-page .logo{
    width: 110px;
    top: -24px;
    left: 20px;
    strong{
      font-size: 17px;
      letter-spacing: -0.4px;
      line-height: 21px;
    }
  }
}
