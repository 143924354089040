.header__mobile-component {
  $mobile-component__size: 1;

  display: none;
  width: 100%;
  background: url(../Images/bg/header-bg.png) repeat;
  padding: 10px 11px 10px 10px;
  position: relative;
  z-index: 1;
  .wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title-with-logo{
    width: 0;
    opacity: 0;
    font-family: $typo-3;
    font-size: em(1.6, $mobile-component__size);
    font-weight: $bold;
    text-transform: uppercase;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-burger{
      width: em(.7, $mobile-component__size);
      height: 1px;
      display: inline-block;
      background: $color-1--1;
      position: relative;
      margin-right: 10px;
      &:before, &:after{
        content: '';
        width: 100%;
        height: 1px;
        background: $color-1--1;
        position: absolute;
        top: -4px;
        left: 0;
      }
      &:after{
        top: 4px;
      }
    }
  }
  .burger-btn{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid $color-3--3;
    background: $color-3--3;
    box-shadow: inset 0 0 0 1px lighten($color-1--1, 5%);
    padding: 13px 15px;
    cursor: pointer;
    float: right;
    position: relative;
    top: 1px;
    z-index: 10;
    &:hover{
      opacity: .9;
    }
    span{
      width: 100%;
      height: 1px;
      display: inline-block;
      background: $color-1--1;
      position: relative;
      -webkit-transition: all .15s ease-in-out;
      transition: all .15s ease-in-out;
      &:before, &:after{
        content: '';
        width: 100%;
        height: 1px;
        background: $color-1--1;
        position: absolute;
        top: -4px;
        left: 0;
        -webkit-transition: all .15s ease-in-out;
        transition: all .15s ease-in-out;
      }
      &:after{
        top: 4px;
      }
    }
    &.active{
      background: $color-white;
      border-color: $color-white;
      box-shadow: inset 0 0 0 1px lighten($color-2--2, 20%);
      padding: 14px 13px;
      span{
        height: 2px;
        background: $color-2--3;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        &:before{
          height: 2px;
          background: $color-2--3;
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
          top: 0;
        }
        &:after{
          display: none;
        }
      }
    }
  }
}
.menu-show{
  .header__mobile-component{
    top: 0;
  }
}
.home .header__mobile-component{
  background: transparent;
  position: absolute;
  top: -21px;
  .burger-btn{
    top: 0;
  }
}
