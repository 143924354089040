.space-citizen {
    max-width: 74.8em;
    margin: 0 auto 8.7em;
    .home-wrap {
        padding: 0 1em;
    }
    .title-3 {
        max-width: 65rem;
        font-size: 3.5em;
        margin-bottom: 1.3em;
    }
    &__content {
        //display: block;
        margin-bottom: 6.1em;
        flex-wrap: wrap;
    }
    &__publication {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.1em;
    }
    &__image {
        width: 50.1%;
        img {
            width: 100%;
        }
    }
    &__wrapper {
        width: 46.7%;
        padding-top: 9%;
    }
    &__citoyen {
        width: 50.1%;
        display: inline-block;
        vertical-align: top;
        margin-right: 1.8em;
        flex-grow: 1;
        margin-left: 0;

        .space-citizen--title {
            margin-top: 0;
            margin-left: 3px;
        }
        ul {
            margin-bottom: 1.8em;
        }
        li a {
            padding: 0.69em 70px 0.69em 54px;
            &::before {
                right: 10px;
            }
        }
    }
    &__form {
        width: 47%;
        display: inline-block;
        vertical-align: top;
        padding-top: 3px;
        margin-left: 0;
    }
}
