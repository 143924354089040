.bloc-actualite {
  width: 83%;
  margin-bottom: 1.6em;
  &__item {
    display: flex;
    justify-content: space-between;
  }
  &__listitems {
    margin-top: 4.1em;
  }
  .list-type-2__picture {
    max-width: 270px;
    min-width: 270px;
    img{
      width: 100%;
    }
  }
  &__wrapper {
    margin-left: 3.3em;
  }
  .teaser-1 {
    line-height: normal;
    margin-bottom: 1.8em;
  }
}

.section-main__aside {
  > .bloc-actualite {
    .category {
      margin-top: 0;
      margin-bottom: 0;
      span {
        padding-left: 1.2em;
      }
    }
  }
}

.bloc-actualite__item-inside {
    display: flex;
    justify-content: space-between;
}
