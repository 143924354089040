.ddm {
    position: relative;
    .ddm__sub-level {
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 100;
        display: none;
    }
    // Sub-level displayed
    &.ddm--active {
        > .ddm__sub-level {
            display: block;
        }
    }
    &.ddm--off {
        .ddm__title {
            display: none;
        }
        .ddm__sub-level {
            position: static;
            top: inherit;
            right: inherit;
            z-index: inherit;
            display: block;
        }
    }
    &.ddm--on {
        .ddm__title {
            display: block;
        }
    }
}
