.site-infos{
  width: 100%;
  margin-bottom: 2em;
  .site-infos__img-wrap{
    width: 100%;
  }
  .block-link{
    padding: 1.9em 0 0;
    justify-content: center;
    &__item{
      & + .block-link__item{
        margin-left: 3%;
      }
    }
  }
  .site-infos__img-wrap{
    img{
      width: 84px;
      height: auto;
      margin-top: -14px;
      padding-left: 12px;

    }
  }
  .site-infos__wrapper{
    width: 100%;
    padding-top: 0;
    margin-top: -4px;
    text-align: center;
    .button-1{
      margin-top: 1.4em;
    }
    p{
      span{
        display: block;
      }
    }
  }
}