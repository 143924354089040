.image-gallery {
    margin-bottom: 7em;
    .head {
        margin-bottom: 2.6em;
        max-width: 54rem;
    }
    .title-3 {
        margin-bottom: 0.31em;
        font-size: 3.5em;
        .wrap {
            &:before, &:after {
                font-size: 1em;
            }
            &:before {
                left: -4px;
            }
            &:after {
                right: -4px;
            }
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        & > div {
            &:hover {
                .image-gallery__wrap-image,
                .videos__item {
                    &:after {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    &__col-large {
        flex: 100% 0 0;
        float: none;
        width: auto;
        figure {
            overflow: hidden;
            height: 100%;
        }
        .image-gallery__wrap-image {
            float: left;
            width: 66.6%;
            padding-bottom: 50%;
        }
        .image-gallery__wrap {
            //float: left;
            width: 33.3%;
            height: 100%;
            display: flex;
            align-items: center;
        }
        .image-gallery__text {
            margin: 0;
            padding: 5.9em 3.3em 2em;
        }
        .image-gallery__text-category {
            margin-bottom: 0.3em;
        }
    }
    &__col-small {
        flex: 33.3% 0 0;
        float: none;
        width: auto;
    }
    &__link {
        .image-gallery__wrap {
            background: none;
        }
    }
    &__wrap-image,
    .videos__item {
        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $color-2--3;
            opacity: 0;
            transition: opacity ease .3s;
            content: "";
        }
    }
    &__wrap {
        display: block;
        position: static;
        padding: 0;
        opacity: 1;
    }
    &__text {
        margin: -2.1em 0 0;
        padding: 4.9em 1em 1.3em;
        min-height: 15.3em;
        &:before {
            transform: translate(-50%, 0);
        }
    }
    &__text-category {
        font-size: 1.2em;
    }
    &__text-legend {
        font-size: 1.4em;
    }
}
