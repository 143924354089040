.home-web-slider{
  padding: 5.7em 0 5.1em;
  margin: 0 2em;

  //.web-list{
  //  width: 560px;
  //}
  //.web-item{
  //  &__img{
  //    margin-bottom: 2.1em;
  //    &::before{
  //      width: 81%;
  //    }
  //    &::after{
  //      width: 90%;
  //    }
  //  }
  //}
  .prevNextCaroussel2{
    padding: 0 3%;
    top: 45%;
  }
}
