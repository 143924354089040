// @name Title 1
// @description Styling for title 1
.title-1 {
    $title--1__font-size: 3.2;
    font-size: #{$title--1__font-size}em;
    margin: em(2.5, $title--1__font-size) 0 em(1.5, $title--1__font-size);
}
$title--3__small_font-size: 1;
.title-3 {
    font-family: $typo-3;
    font-size: em(2, $title--3__small_font-size);
}

