// @name bloc event

// @name Bloc event in column
$bloc-form__font-size: 1;
.section-main__aside > .column-bloc .bloc-form--title {
  margin-top: 15px;
}

.bloc-form {
  width: 23em;
  margin-top: em(1.5, $bloc-form__font-size);
  background: $color-3--5;
  padding: em(2, $bloc-form__font-size);

  &--title {
    font-family: $typo-3;
    font-size: em(2.2, $bloc-form__font-size);
    line-height: 1em;
    font-weight: $light;
    text-transform: uppercase;
    color: $color-2--3;
    margin: em(1.5, $title--3__font-size) 0 em(2, $title--3__font-size);
    padding-bottom: em(1.6, $title--3__font-size);
    &:before {
      content: '//';
      color: $color-2--3;
      position: relative;
      margin-right: 4px;
    }
  }
  &__item {
    form {
      margin-bottom: 20px;
    }
    input[type="text"], input[type="password"] {
      background: $color-white;
      //margin-bottom: em(0.9, $bloc-form__font-size);
      box-sizing: border-box;
      //padding-left: 1em;
      @include placeholder{
        color: $color-3--3;
      }
    }
  }
  .form__forgot {
    color: $color-2--3;
    font-family: $typo-3;
    font-size: em(1.4, $bloc-form__font-size);
    font-weight: $light;
    float: left;
    position: relative;
    top: 10px;
  }
  .login-name{
    color: $color-2--3;
    font-family: $typo-3;
    font-size: em(1.4, $bloc-form__font-size);
    font-weight: $light;
    position: relative;
    padding-left:20px;
    margin-left: 15px;
    &:before{
      content:'';
      display:block;
      background: url('../Images/content/user.png') no-repeat;
      width:16px;
      height:16px;
      position: absolute;
      left:0;
      top:3px;
    }
  }
  .form__submit {
    padding: em(0.96429, $bloc-form__font-size) em(1.84286, $bloc-form__font-size) em(1, $bloc-form__font-size) em(2.47143, $bloc-form__font-size);
    float: right;
    background: $color-2--4;
    &:before {
      left: 1em;
    }
    &:hover {
      background: $color-2--2;
    }
  }
  .form__deconnexion {
    padding: em(0.96429, $bloc-form__font-size) em(1.84286, $bloc-form__font-size) em(1, $bloc-form__font-size) em(2.47143, $bloc-form__font-size);
    float: left;
    background: $color-2--4;
    font-weight:$normal;
    &:before {
      left: 1em;
      content: '\e002';
    }
    &:hover {
      background: $color-2--2;
    }
  }
}
.section-main__aside > .column-bloc .form__deconnexion{
  margin-top:1em;
}
