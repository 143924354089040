.parallax {
  height: 540px;
  //background-attachment: fixed;
  background-position: center center;
  background-size: 100%;
}

/*Edge*/
@supports ( -ms-accelerator: true ) {
    html {
        overflow: hidden;
        height: 100%;
    }

    body {
        overflow: auto;
        height: 100%;
    }
}

/*Ie 10/11*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html {
        overflow: hidden;
        height: 100%;
    }

    body {
        overflow: auto;
        height: 100%;
    }
}
