// @name Go so far
// @description Sections content below the content

.go-so-far {
    display: none;
    background: $color-3--1;
    padding: 3.6em 0 2.7em;

    .go-so-far__title {
        font-size: 3em;
        font-weight: $light;
        text-align: center;
        color: $color-3--3;
        margin-top: 0;
        box-sizing: border-box;
        &::before {
            top: 0.35em;
        }

        .wrap {
            background: $color-3--1;
            &::before {
                left: -5px;
            }
            &::after {
                right: -4px;
            }
            .inner {
                padding: 0 1.1em;
            }
        }
    }
    &__wrapper {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        > * {
            flex: 1;
            margin: 0 2%;
            &:last-child {
                flex: 1.5;
            }
        }
    }
    .list-content-2,
    .rte .list-content-2 {
        padding-right: 10%;
        > li {
            padding-left: .7em;
            margin: 0 0 .55em;
            &::before {
                left: -3px;
            }
        }
    }
    .title-2 {
        font-size: 2.1em;
        color: $color-2--3;
        padding-left: 0.9em;
        margin: 0.9em 0 1.3em;
        @include oblique-lines(1em, $color-2--3, 3px);
    }
    .bloc-publications {
        &__title {
            font-family: $typo-3;
            font-size: 2em;
            text-transform: uppercase;
            color: $color-2--3;
            padding-top: 6px;
            margin-bottom: 0.3em;
            a {
                display: block;
                color: $color-2--3;
                &:hover, &:focus {
                    color: $color-1--2;
                }
            }
        }
        &__picture {
            width: 12em;
            float: left;
            margin-right: 1.7em;
            img {
                width: 100%;
            }
        }
        &__wrapper {
            overflow: hidden;
        }
        &__subtitle {
            font-size: 1.6em;
            margin-bottom: 1.4em;
        }
    }
    .list-document-1__item {
        font-family: $typo-3;
        &::before {
            margin-right: 1px;
        }
    }
}

.go-so-far-wrapper {
    display: block;
    margin: 0 auto 4rem;
    max-width: 118rem;
    padding: 0 1rem;

    .go-so-far.go-so-far_small {
        margin-top: 0;
    }
}

.go-so-far.go-so-far_small {
    display: flex;
    justify-content: space-between;
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: .2em;
    margin-top: 2em;
    max-width: calc(100% - 35rem);
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;

    .go-so-far__title {
        width: 100%;
        margin-right: 1.8em;
        max-width: 50%;
    }

    .go-so-far__item {
        width: 100%;
        max-width: 50%;
    }
}
