//@name Page content

// max width for content element
$content__max-width: ($tablet/10) - $column-bloc__width - $column-bloc__padding-left;

// Select first content element and remove margin-top
.section-main__content span.anchor:first-of-type + .colPos0 .ce-bodytext > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > *:first-child,
    // content in click and roll
.rte.ddm__sub-level > *:first-child,
    // Single view
.heading--single + .section-main__wrapper .section-main__content > .rte > *:first-child {
    margin-top: 0;
}

// @name Title h2
$content-h2__font-size: 3.8;
.rte h2, .rte .h2, .h2 {
    font-family: $typo-3;
    font-size: #{$content-h2__font-size}em;
    line-height: 1.2;
    font-weight: $light;
    text-transform: uppercase;
    color: $color-black;
    padding-left: 60px;
    margin: em(6, $content-h2__font-size) 0 em(1.8, $content-h2__font-size);
    @include oblique-lines-2();
    a {
        color: $color-1--2;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h3
$content-h3__font-size: 2.5;
.rte h3, .rte .h3, .h3 {
    font-family: $typo-3;
    font-size: #{$content-h3__font-size}em;
    line-height: 1.2;
    font-weight: $normal;
    color: $color-black;
    text-transform: uppercase;
    padding-left: 1em;
    margin: 1.55em 0 0.6em;
    @include oblique-lines(1.05em, lighten($color-2--3, 18%), .1em);
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h4
$content-h4__font-size: 2;
.rte h4, .rte .h4, .h4 {
    font-family: $typo-3;
    font-size: #{$content-h4__font-size}em;
    line-height: 1.2;
    color: $color-black;
    font-weight: $normal;
    text-transform: uppercase;
    padding-left: 1em;
    margin: 2em 0 .85em 0;
    @include oblique-lines(1.06em, $color-1--2, .06em);
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h5
$content-h5__font-size: 1.5;
.rte h5, .rte .h5, .h5 {
    font-family: $typo-3;
    font-size: 1.5em;
    line-height: 1.2;
    color: $color-2--3;
    font-weight: $normal;
    margin: 1.8em 0 1.21em;
    a {
        color: $color-3--3;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.rte h6, .rte .h6, .h6 {
    font-family: $typo-3;
    font-size: #{$content-h6__font-size}em;
    line-height: 1.2;
    color: $color-black;
    margin: em(2, $content-h6__font-size) 0 em(1, $content-h6__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

$content-list__font-size: 1.4;
.rte {
    // @name Link
    a {
        color: $color-2--3;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    //  @name External link
    a[href^="http://"]:not([href*="#{$domain}"]),
    a[href^="https://"]:not([href*="#{$domain}"]),
    a[href^="//"]:not([href*="#{$domain}"]) {
        //@include default-icons-after('\e086', 0 0 0 5px, 8px, $color-black, middle);
        @include default-icons-after("\e06d", 0 0 0 4px, em(1, 1), $color-2--3, middle);
    }

    // @name Paragraph
    $content-paragraph__font-size: 1.5;
    p {
        font-size: #{$content-paragraph__font-size}em;
        font-weight: $normal;
        line-height: normal;
        margin: em(.5, $content-paragraph__font-size) 0 em(1, $content-paragraph__font-size) 0;
        & + .ce-textpic {
            margin-top: -7px;
        }
    }

    // @name Content chapeau
    // @description Specific style for paragraph
    .chapeau {
        font-size: 1.5em;
        color: $color-3--3;
        a {
            color: $color-3--3;
        }
    }

    // @name Content encadre
    // @description Specific style for paragraph
    .encadre {
        $encadre__font-size: 1.6;
        line-height: 1.3em;
        padding: 22px 40px;
        font-size: #{$encadre__font-size}em;
        background: $color-3--5;
        margin: 1.13em 0 1.6em;
    }

    // @name Blockquote
    $content-blockquote__font-size: 1.5;
    $content-blockquote-icon-1__font-size: 3.2;
    blockquote {
        font-size: #{$content-blockquote__font-size}em;
        font-weight: $light;
        font-style: italic;
        padding: 1.5rem 1rem 1.5rem 6.5rem;
        margin: 2.5rem 0 1rem;
        color: $color-3--3;
        @include default-icons-absolute-before('\e06f', 2.3em, $color-2--3, 50%, inherit, inherit, 0);
        &::before {
            font-style: normal;
            transform: translateY(-50%);
        }

        &::after {
            content: '';
            width: 2px;
            height: calc(100% - 2rem);
            display: block;
            position: absolute;
            left: 4rem;
            top: 1rem;
            //margin-top: -2.5rem;
            background: $color-3--2;
            transform: skew(-11deg);
        }

        p {
            display: block;
            font-size: 1em;
            font-weight: $light;
            margin: 0;
            color: #727272;
            font-style: italic;
        }
    }

    // @name Unordered list
    ul, .show-hide__content ul {
        margin: 1.5rem 0 1.5rem 2.5rem;
        list-style: none;
        font-size: 1.5em;
        color: $color-black;
        padding: 0;
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            padding-left: 1em;
            margin-bottom: 0.9em;
            @include oblique-lines(1.07em, $color-2--3, 0);
            &::before {
                font-family: $typo-3;
            }
            > ul, > a + ul { // for specificity selector
                font-size: 1em;
                list-style: none;
                padding: .1rem 0 .1rem 0;
                margin: 0 0 0 .7em;
                > li {
                    padding-left: 1.5rem;
                    margin: 0.2em 0 0.3em;
                    @include default-icons-absolute-before("\e027", 1rem, $color-2--3, .5rem, inherit, inherit, 0);
                }
            }
            > ol, > a + ol { // for specificity selector
                font-size: 1em;
                counter-reset: li;
                padding: .1rem 0 .3rem 0;
                margin: 0 0 0 .7em;
                > li {
                    list-style: none;
                    padding: 0;
                    margin: 0.2em 0 0.3em 1.2em;;
                    &::before {
                        counter-increment: li;
                        content: counter(li) ". ";
                        color: $color-black;
                        font-family: $typo-3;
                        font-size: 1.07em;
                        margin-right: 3px;
                        margin-left: -1.1em;
                    }
                }
            }
        }
    }

    // @name Ordered list
    ol, .show-hide__content ol {
        margin: 1.2rem 0 1.5rem 0;
        padding: 0 0 0 1rem;
        list-style: decimal;
        font-size: 1.5em;
        color: $color-black;
        counter-reset: li;
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            padding-left: 1em;
            margin-bottom: 0.75em;
            margin-left: 1.2em;
            list-style: none;
            &::before {
                counter-increment: li;
                content: counter(li) ". ";
                color: $color-black;
                font-family: $typo-3;
                font-size: 1.07em;
                margin-right: 3px;
                margin-left: -1.1em;
            }
            > ol, > a + ol { // for specificity selector
                font-size: 1em;
                padding: .1rem 0 .1rem 0;
                margin: 0 0 0 .4em;
                > li {
                    padding: 0;
                    margin: 0.2em 0 0.3em 1.2em;
                    &::before {
                        font-family: $typo-1;
                        font-size: 1em;
                        margin-right: 3px;
                        margin-left: -1.1em;
                    }
                }
            }
            > ul, > a + ul { // for specificity selector
                font-size: 1em;
                list-style: none;
                padding: .1rem 0 .3rem 0;
                margin: 0 0 0 .4em;
                > li {
                    padding-left: 0.8em;
                    margin: 0.2em 0 0.3em;
                    @include default-icons-absolute-before("\e023", 0.85rem, $color-black, .4rem, inherit, inherit, 0);
                }
            }
        }
        & + h3 {
            margin: 2.1em 0 0.55em;
        }
    }

    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol, > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }

    // @name Table
    .table-wrapper {
        margin: 4.3em 0 7em;
        &:hover {
            .table-wrapper-inner {
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, .5);
                }
            }
        }
    }
    .table-wrapper-inner {
        max-width: #{$content__max-width}em;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            width: 7px;
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
            -webkit-transition: all .15s ease-in-out;
            transition: all .15s ease-in-out;
        }
    }
    table {
        width: 100%;
        font-size: 1em;
        ul {
            font-size: 1em;
        }
        caption {
            $table__caption_font-size: 1.6;
            font-family: $typo-3;
            text-align: left;
            font-weight: $light;
            font-size: #{$table__caption_font-size}em;
            color: $color-black;
            margin-bottom: 0.75em;
        }
        th, td {
            text-align: center;
            padding: 13.5px 14px 12px;
            border: none;
        }
        th {
            font-size: 1.4em;
            text-transform: uppercase;
            font-family: $typo-3;
            font-weight: $normal;
            color: $color-2--3;

        }
        thead th {
            font-size: 1.4em;
            background: $color-3--5;
            font-weight: $bold;
            color: $color-black;
        }
        tbody th {
            text-align: right;
            border-bottom: 1px solid $color-3--2;
        }
        td {
            font-size: 1.4em;
            color: $color-black;
            background: $color-white;
            border-bottom: 1px solid $color-3--2;
        }
    }

    // @name column 2
    .column--2, .column--3 {
        margin: 2.8em 0 2em;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        & > *:not(:last-child) {
            padding-right: 30px;
        }
    }
    .column--2 {
        & > * {
            width: 50%;
        }
    }
    .column--3 {
        & > * {
            width: 33.3%;
        }
    }
    .link-icon {
        @include default-icons-after("\e06d", 0 0 0 4px, em(1, 1), $color-2--3, middle);
    }
}
