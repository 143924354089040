.bloc-agenda {
    width: 89%;
    display: block;
    margin-bottom: 2.8em;
    &__item {
        display: flex;
        justify-content: space-between;
    }
    &__listitems {
        margin-top: 43px;
    }
    .list-type-2__picture {
        max-width: 270px;
        min-width: 270px;
    }
    &__wrapper {
        margin-left: 90px;
        .hour-place {
            text-align: left;
            a {
                color: $color-2--3;
                font-size: 13px;
            }
        }
    }
    .pic-wrapper {
        position: relative;
        .date-1 {
            position: absolute;
            right: -60px;
            top: 10px;
            time {
                display: block;
                float: left;
                margin: 0;
                background: $color-2--3;
                color: $color-1--1;
                font-weight: $bold;
                font-family: $typo-3;
                padding: 2em 2.4em;
                font-size: 6px;
                + span + time {
                    background: $color-3--3;
                    color: $color-1--1;
                    &:before {
                        display: none;
                    }
                }
                .month {
                    text-transform: uppercase;
                    font-weight: $light;
                    font-size: 16px;
                }
            }
        }
    }
    .button-2 {
        padding: 0.89286em 2.28571em 0.89286em 3em;
    }
}

.home .block-agenda {
    margin-top: 0;
    margin-bottom: 4.6em;
    top: 0;
}

.section-main__aside {
    > .bloc-agenda {
        .category {
            margin-top: 0;
        }
    }
}
