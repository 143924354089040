body:not(.home){
  .block-agenda{
    position: relative;
    margin: 0 0 5em;
    padding: 0 2.4em 4.7em;
    &__head{
      margin-bottom: 1.7em;
      max-width: 20rem;
    }
    .title-3{
      font-size: 2em;
      &:before{
        margin-top: -1px;
      }
      .wrap{
        &:before{
          left: -4px;
        }
        &:after{
          right: -4px;
        }
        .inner{
          padding: 0 1.2em;
        }
      }
    }
    .link-all-dates{
      left: 50%;
      transform: translate(-50%, 0);
    }
    &__list{
      width: 100%;
    }
    &__item{
      &:first-child{
        margin: 0 -1em;
      }
    }
    .picture{
      position: relative;
      float: none;
      display: block;
      margin: 0;
      width: 100%;
      height: auto;
      &:before{
        display: block;
        padding-top: 66.5%;
        content: "";
      }
      a{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      img{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
      }
    }
    &__wrapper{
      overflow: visible;
      padding: 1.8em 0 0 11.7em;
    }
    .date,
    .date-1{
      top: -6em;
      left: 4.7em;
      time{
        float: none;
      }
    }
    &__title{
      margin: 0 -1em 0.55em 0;
    }
  }
}
.home{
  .block-agenda{
    margin-bottom: 2.6em;
    &__head{
      margin-bottom: 1.7em;
      max-width: 100%;
    }
    .title-3{
      margin-bottom: 0.24em;
      font-size: 3.2em;
    }
    &__item{
      flex: 100% 0 0;
      background: $color-white;
      &:nth-child(2n+2){
        background: $color-3--12;
      }
      .block-agenda__wrapper{
        padding: 1.5em 0 1.7em 11em;
      }
      .date-1,
      .date{
        left: 1.7em;
      }
      .block-agenda__title{
        margin-bottom: 0.63em;
        font-size: 1.9em;
      }
      .hour-place{
        font-size: 1.4em;
      }
    }
    .picture{
      height: auto;
    }
    &__focus{
      padding: 0.6em;
      .block-agenda__wrapper{
        padding: 1.8em 1em 1.2em 11.7em;
      }
      .date,
      .date-1{
        top: -6.7em;
        left: 2.7em;
        time{
          width: 6.7em;
          height: 6.7em;
        }
      }
      .block-agenda__category{
        margin-bottom: 0.8em;
      }
      .block-agenda__title{
        margin-bottom: 0.74em;
        font-size: 1.8em;
      }
      .block-agenda__text{
        margin-bottom: 0.76em;
      }
    }
  }
}
