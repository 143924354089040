// # Global
$domain: "fontainebleau.fr";

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Lora:400,400i,700";
$import-google-font-2: "https://fonts.googleapis.com/css?family=Oswald:300,400,700";

$typo-1: 'Lora', serif;
$typo-2: $typo-1;
$typo-3: 'Oswald', sans-serif;

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$image-bg-url: "../Images/bg/";
$image-fancybox-url: "../Images/fancybox/";
$font-path: "../Fonts/";

// ## Media queries
$smartphone: 767;
$tablet: 1279;

// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;

// ## color-1
$color-1--1: #dfec9c;
$color-1--2: #bed646;
$color-1--3: #00aa57;

// ## color-2
$color-2--1: #6fcad9;
$color-2--2: #559ca8;
$color-2--3: #106674;
$color-2--4: #a3c9cf;

// ## Color-3
$color-3--1: #ededf1;
$color-3--2: #a9acb9;
$color-3--3: #3d424c;
$color-3--4: #f8f8f9;
$color-3--5: #e2f4f7;
$color-3--6: #0b4852;
$color-3--7: #78735c;
$color-3--8: #2f343e;
$color-3--9: #A40015;
$color-3--10: #f2f7da;
$color-3--11: #fdfdfe;
$color-3--12: #f6f6f8;
$color-3--13: #fbfbfc;
$color-3--14: #eeeef1;
$color-3--15: #4c4c4c;
