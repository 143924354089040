// @name _owlcarousel
.image-gallery__list2{
  .owl-carousel, .owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }

  .owl-carousel {
    display: none;
    width: 100%;
    z-index: 1;
  }

  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
  }

  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
  }

  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
  }

  .owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
    display: none;
  }

  .no-js .owl-carousel, .owl-carousel.owl-loaded {
    display: block;
  }

  .owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-indent: -9999px;
  }

  .owl-carousel.owl-loading {
    opacity: 0;
    display: block;
  }

  .owl-carousel.owl-hidden {
    opacity: 0;
  }

  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
  }

  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-carousel.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .owl-carousel.owl-rtl {
    direction: rtl;
  }

  .owl-carousel.owl-rtl .owl-item {
    float: right;
  }

  .owl-carousel .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .owl-carousel .owl-animated-in {
    z-index: 0;
  }

  .owl-carousel .owl-animated-out {
    z-index: 1;
  }

  .owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .owl-height {
    -webkit-transition: height .5s ease-in-out;
    transition: height .5s ease-in-out;
  }

  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease;
  }

  .owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }

  .owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url(owl.video.play.png) no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: -webkit-transform .1s ease;
    -webkit-transition: -webkit-transform .1s ease;
    transition: transform .1s ease;
    transition: transform .1s ease, -webkit-transform .1s ease;
  }

  .owl-carousel .owl-video-play-icon:hover {
    -webkit-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }

  .owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
    display: none;
  }

  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease;
  }

  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
  .image-gallery .image-gallery__list2 .owl-nav .owl-prev::before, .image-gallery .image-gallery__list2 .owl-nav.disabled .owl-prev::before,
  .image-gallery .image-gallery__list2 .owl-nav .owl-next::before, .image-gallery .image-gallery__list2 .owl-nav.disabled .owl-next::before {
    text-indent: 1px;
  }
  .owl-nav{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 1em;
    .owl-prev,
    .owl-next{
      position: absolute;
      padding-bottom: 75%;
      background: transparent;
      top: 0;
      width: 4.8em;
      &:before{
        position: absolute;
        top: 50%;
        left: 0;
        margin: 0 1.4em;
        border: 1px solid $color-white;
        border-width: 1px 0 0 1px;
        width: 2em;
        height: 2em;
        content: "";
      }
      &:hover,
      &:focus{
        background: rgba($color-2--3, 0.8);
      }
    }
    .owl-prev{
      left: 0;
      &:before{
        transform: rotate(-45deg) skew(10deg, 10deg) translate(0, 0.4em);
      }
    }
    .owl-next{
      right: 0;
      &:before{
        transform: rotate(135deg) skew(10deg, 10deg) translate(0.4em, -0.2em);
      }
    }
  }
}