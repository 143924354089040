$bloc-projects__font-size: 1em;
.bloc-projects{
  font-size: $bloc-projects__font-size;
  margin-bottom: 7.9em;
  &__head{
    max-width: 76.6rem;
    margin: 0 auto 2.4em;
    text-align: center;
  }
  .title-3{
    position: relative;
    margin-bottom: 0.16em;
    font-size: 4em;
    line-height: 1.2;
    color: $color-black;
    &:before{
      position: absolute;
      top: 50%;
      margin: 1px 0 0;
      background: $color-1--2;
    }
    .wrap{
      &:before, &:after{
        top: 50%;
        transform: translate(0, -50%);
        font-size: 0.85em;
        color: $color-1--2;
      }
      &:before{
        left: -6px;
      }
      &:after{
        right: -6px;
      }
    }
  }
  .link-all-projects{
    font: $light 1.2em/1.25 $typo-3;
    text-transform: uppercase;
    color: $color-black;
    &:before{
      content: "/";
    }
  }
  &__wrap{
    position: relative;
  }
  &__text{
    display: flex;
    align-items: center;
    position: absolute;
    left: 3.2em;
    top: 50%;
    transform: translateY(-57%);
    background: url('../Images/bg/projects-bg.png') no-repeat;
    width: 29em;
    height: 29em;
    text-align: center;
    .wrap{
      width:100%;
      padding: 0.2em 3em 0;
    }
  }
  &__category{
    position: relative;
    display: block;
    margin: 0 0 0.8em;
    padding: 0 0 0.95em;
    font-weight: $bold;
    font-size: 1.2em;
    line-height: 1.2;
    text-transform: uppercase;
    &:after{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: rgba($color-2--3, 0.3);
      width: 3.2em;
      height: 1px;
      content: "";
    }
  }
  h3{
    margin: 0 0 0.6em;
    font: $bold 1.8em/1.2 $typo-3;
    text-transform: uppercase;
    color: $color-2--3;
    letter-spacing: -0.01em;
    a{
      color: $color-2--3;
    }
  }
  p{
    font-size: 1.2em;
    line-height: 1.2;
  }
  a{
    color: $color-black;
  }
  .more{
    @include default-icons-before('\e08a', $margin: 0 0 0 0.7em, $size: 2em, $color: $color-2--1);
    position: absolute;
    right: 2.1em;
    bottom: 1.8em;
    width: 5em;
    height: 5em;
    &:before{
      line-height: 3.1;
    }
    &:hover,
    &:focus{
      &:before{
        color: $color-2--3;
      }
    }
    span{
      font-size: 0;
      line-height: 0;
    }
  }
}
