// Galerie classique (avec vignettes) + Galerie album
.classical-gallery {
  position: relative;
  margin: 5.5em auto 6em;
  &__title {
    padding: 0 10px;
  }
  &__nb-items {
    display: none;
    padding: 0 10px;
    @include background-opacity($color-black, .8);
    color: $color-white;
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
    font-size: 3em;
    margin: 0;
  }
}

.slider-galerie {
  &__item {
    display: none;
    &:first-child {
      display: block;
    }
  }
  .infos-img {
    padding: 8px 10px 0;
  }
}
