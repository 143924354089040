.title-text{
  $title-text__size: 1.4;

  width: 100%;
  position: absolute;
  top: 25.2%;
  left: 0;
  text-align: right;
  &__wrap{
    max-width: 47.1em;
    display: inline-block;
    font-weight: $bold;
    text-align: left;
    padding: 1em;
  }
  a{
    display: inline-block;
    text-decoration: none;
  }
  &__subtitle{
    font-size: 1.4em;
    text-transform: uppercase;
    background: rgba(55,64,76, .7);
    padding: em(1.15, $title-text__size) em(1.05, $title-text__size);
    margin-bottom: 0.4em;
    &:hover{
      background: rgba(55,64,76, 1);
    }
  }
  &__title{
    font-family: $typo-3;
    font-size: 4em;
    line-height: 1.2em;
    text-transform: uppercase;
    padding-right: 15px;
  }
}
