// @name Page number
// @description Page id visible only when we connected in BE.
.page-number {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    color: color-contrast($color-black);
    padding: 5px;
}
