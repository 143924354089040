.vous-constatez {
    background: $color-2--2 url('../Images/bg/vous-bg.jpg') repeat;
    position: relative;
    padding-left: 26.8em;
    &__phone {
        max-width: 15.8em;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        img {
            display: block;
            border-radius: 50%;
        }
    }
    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }
    &__text-block {
        width: 58%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3em 1em 3em 0;
        .text-container {
            color: $color-white;
            border-left: 1px solid $color-white;
            padding-left: 30px;
            &__title {
                max-height: em(2.2, 2);
                font-size: 2em;
                line-height: 1.1em;
                text-transform: uppercase;
                overflow: hidden;
                margin-bottom: 0.2em;
            }
            p {
                max-height: em(4.4, 1.7);
                font-size: 1.7em;
                font-family: $typo-3;
                font-weight: $light;
                overflow: hidden;
            }
        }
        .img-holder {
            width: 6.1em;
            margin-right: 29px;
        }
    }
    &__access-block {
        width: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color-white;
        padding: 22px 44px 22px 6%;
        position: relative;
        z-index: 1;
        &::before {
            content: '';
            width: 50em;
            height: 30em;
            background: $color-2--3;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            z-index: -1;
        }
        a {
            display: inline-block;
        }
        .access-link {
            font-size: 2em;
            color: $color-white;
            text-decoration: none;
            margin-right: 10px;
            &:hover, &:focus,
            &:active {
                color: $color-1--2;
            }
        }
        .more-link {
            width: 3.22em;
            height: 3.22em;
            background: none;
            border-radius: 50%;
            border: 1px solid rgba(255, 255, 255, .3);
            padding: 0;
            &:before {
                content: "\e017";
                font-size: 1.8em;
                text-align: center;
                color: $color-white;
                left: 0;
                right: 0;
            }

            &::after {
                display: none !important;
            }
            &:hover {
                background: $color-white;
                border-color: $color-white;
                &:before {
                    color: $color-2--3;
                }
            }
        }
    }
}
