.vous-constatez{
  padding-left: 0;
  &__phone {
    max-width: 14.1em;
    margin: 0 auto -4px;
    text-align: center;
    position: relative;
    top: -30px;
    left: inherit;
    transform: none;
    img{
      display: inline-block;
    }
  }
  &__wrap{
    display: block;
    overflow: inherit;
    & > *{
      width: 100%;
    }
  }
  &__text-block{
    padding: 0 19px;
    margin-bottom: 2.4em;
    .text-container{
      padding-left: 18px;
      &__title{
        margin-top: -8px;
      }
      p{
        max-height: em(1.6, 1.2);
        font-size: 1.4em;
      }
    }
    .img-holder{
      margin-right: 20px;
    }
  }
  &__access-block{
    background: $color-2--3;
    padding: 12px 22px 11px 17%;
    &::before{
      display: none;
    }
    .access-link{
      margin-right: 0;
    }
    .more-link{
      width: 3.36em;
    }
  }
}
