.menu-main-home {
    display: flex;
    li {
        a {
            &::after {
                right: 8px;
                bottom: 10px;
            }
            .wrap {
                width: 153px;
                height: 153px;
                &::after {
                    width: 25px;
                    height: 25px;
                    right: 9px;
                    bottom: 10px;
                }
            }
            .title {
                font-size: 14px;
                margin: 17px 0 8px;
            }
            .text {
                height: em(7.35, 1.4);
                font-size: 12px;
                line-height: 12px;
                padding: 18px em(2, 1.2) 0;
                &::after {
                    right: 7px;
                    left: 7px;
                }
            }
        }
    }
    &__holder {
        padding-bottom: 1em;
        margin-top: -5.7em;
        //margin-bottom: 6rem;
        margin-bottom: 0;

        &::after {
            height: 195px;
            bottom: -45px;
        }
    }
}

.smartphone-menu {
    display: none;
}

.menu-main-home {
    .menu-main__level-1 {
        > li {
            margin-top: -4.5rem !important;
        }
    }

    .menu-main__level-2-wrap {
        width: 100%;
        left: 0;
        padding: 2rem 5% 0;
    }

    .menu-main__level-2 {
        min-height: 38em;
    }

    &.menu-main button.menu-closer {
        top: 2rem;
    }

    .menu-main__level-3.menu-level-content {
        &::after {
            top: -127px;
            height: 127px;
            border-radius: 50px 0 0 0;
        }
    }

    .menu-main__level-4.menu-level-content {
        &::after {
            top: -127px;
            height: 127px;
            border-radius: 0 90% 0 0;
        }
    }
}
