.menu-main {
    &__level-1 {
        padding-left: 13%;
        & > li {
            & > a {
                padding: 0.4em 27px;
            }
        }
    }
}

.menu-main__level-3 > li > a {
    &.menu-main__link {
        padding-left: 35px;
    }
}
