.home-newsletter{
  width: 38em;
  .home-newsletter__title{
    text-align: center;
    font-size: 3em;
    color: $color-3--3;
    margin-bottom: 0.75em;
    &::before{
      background: lighten($color-1--2, 5%);
      top: 0.4em;
    }
    .wrap {
      &::before {
        color: lighten($color-1--2, 5%);
        left: -6px;
      }
      &::after {
        color: lighten($color-1--2, 5%);
        right: -5px;
      }
      .inner {
        padding: 0 1.1em;
      }
    }
  }
  &__wrap{
    width: 30em;
    padding-top: 3.4em;
    margin: 0 auto;
  }
  &__top-block{
    overflow: hidden;
    margin-bottom: 5.7em  ;
    .icon-holder{
      width: 11.2em;
      float: left;
      margin-right: 1.5em;
      img{
        max-width: 100%;
      }
    }
    .description{
      font-size: 1.6em;
      color: $color-2--3;
      overflow: hidden;
      padding: 1.8em 10% 0 0;
    }
  }
  .subscribe-form{
    label{
      margin-bottom: .75em;
    }
    .label-text{
      font-size: 1.12em;
      display: block;
      margin-bottom: 1em;
    }
    .form-field{
      padding: 0.55em 12px 0.8em;
      margin: 0;
      &::-webkit-input-placeholder{
        font-size: .9em;
        color: $color-3--3;
      }
      &::-moz-placeholder{
        color: $color-3--3;
      }
      &:-ms-input-placeholder{
        color: $color-3--3;
      }
    }
    .button-2{
      padding: 1.1em 0.7em 1.1em 2.5em;
      &::before{
        left: 1.1em;
      }
    }
  }
}
