// max width for content element
$content__max-width: ($smartphone/10) - $column-bloc__width - $column-bloc__padding-left;

.rte {

  // @name Paragraph
  $content-paragraph__font-size: 1.4;
  p {
    font-size: #{$content-paragraph__font-size}em;
    margin: em(.5, $content-paragraph__font-size) 0 em(1, $content-paragraph__font-size) 0;
  }
  .encadre {
    max-width: 70rem;
    padding: 20px 27px;
    margin: 1.13em auto 1.4em;
  }
  // @name Table
  .table-wrapper {
    position: relative;
    margin-top: 1.3em;
  }
  .table-wrapper-fade {
    position: absolute;
    right: 0;
    width: 30px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 100%);
  }
  .table-wrapper-inner {
    max-width: 70em;
  }
  table {
    min-width: 500px;
    caption {
      text-align: left;
      margin-bottom: .7em;
    }
  }
  .blockquote-holder{
    margin-bottom: 7.7em;
  }
  blockquote {

  }
  // @name Unordered list
  ul, .show-hide__content ul {
    > li {
      margin-bottom: 0.7em;
      > ul, > a + ul { // for specificity selector
        padding-top: .8em;
        margin: 0 0 0 .7em;
        > li {
          margin: 0;
        }
      }
      > ol, > a + ol { // for specificity selector
        padding-top: .8em;
        margin: 0 0 0 .6em;
        > li {
          margin: 0 0 0 1.2em;
          &::before{
            font-size: 0.94em;
          }
        }
      }
    }
  }

  // @name Ordered list
  ol, .show-hide__content ol {
    > li {
      margin-left: 1.1em;
      margin-bottom: 0.7em;
      &::before{
        font-size: 0.94em;
      }
      > ol, > a + ol { // for specificity selector
        padding-top: .8em;
        margin: 0 0 0 1.7em;
        > li {
          margin: 0;
        }
      }
      > ul, > a + ul { // for specificity selector
        padding-top: .8em;
        margin: 0 0 0 0.6em;
        > li {
          margin: 0;
        }
      }
    }
  }
}
.rte h2, .rte .h2, .h2 {
  font-size: 3.6em;
  padding-left: 50px;
  margin-bottom: 0.6em;
  &::before{
    left: 20px;
  }
  &::after{
    width: 26px;
  }
}
.rte h5, .rte .h5, .h5{
  font-size: 1.4em;
  font-weight: $bold;
  text-transform: uppercase;
  padding-left: 1em;
  margin: 2.5em 0 1.4em;
  @include oblique-lines(1.07em, $color-2--3, 0);
  &::before {
    font-family: $typo-3;
    font-size: 1em;
    top: 2px;
  }
}
.rte .ce-textpic p {
  margin-top: 21px;
}

.rte .column--2{
  & > * {
    width: 49%;
  }
  & > *:not(:last-child) {
    padding-right: 0;
  }
}

.rte .column--3{
  & > * {
    width: 30%;
  }
  & > *:not(:last-child) {
    padding-right: 0;
  }
}
