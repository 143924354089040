// @name Teaser 2
// @description Teaser in heading
.teaser-2 {
  font-size: 1.6em;
  line-height: 1.2em;
}

.caption {
  padding-left: 25px;
  @include default-icons-absolute-before("\e00f", 17px, $color-3--2, 2px, inherit, inherit, 6px);
  &::before{
    font-style: normal;
  }
}
