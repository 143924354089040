.list-type-3__listitems {
    .list-paginated__wrapper {
        > article {
            flex: 0 1 46%;
            max-width: 46%;
        }
    }
}
.list-type-3__picture {
    float: none;
    img {
        margin-right: 0;
        margin-bottom: 1em;
        max-width: 100%;
        height: auto;
    }
}
