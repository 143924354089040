.bloc-projects{
  margin-bottom: -3em;
  &__head{
    margin-bottom: 3.1em;
    max-width: 100%;
  }
  .title-3{
    margin-bottom: 0.24em;
    font-size: 3.2em;
  }
  &__text{
    position: relative;
    left: 1.2em;
    top: -3.8em;
    transform: translateY(0);
    background: url('../Images/bg/projects-bg-mobile.png') no-repeat;
    width: 23.9em;
    height: 23.9em;
    .wrap{
      padding: 1.8em 3em 0;
    }
  }
  &__category{
    padding-bottom: 0.65em;
    &:after{
      width: 2.8em;
    }
  }
  h3{
    font-size: 1.4em;
  }
  p{
    font-size: 1.4em;
    line-height: 1.25;
  }
  .more{
    right: 1.7em;
    bottom: 1.4em;
    width: 4em;
    height: 4em;
    &:before{
      margin-left: 0.6em;
      line-height: 2.2;
      color: $color-2--3;
    }
  }
}
