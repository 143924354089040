$link-font-size: 1;

.link{
  float: right;
  display: flex;
  margin-top: 1.5em;
  &__language{
    display: flex;
    margin-left: 10px;
    li{
      display: flex;
      align-items: center;
      &:first-child{
        a{
          position: relative;
          margin-right: 7px;
          padding-right: 9px;
          &:after{
            content: '';
            display: block;
            background: $color-3--1;
            width: 2px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
    a{
      font-size: em(1.6, $link-font-size);
      font-weight: $bold;
      font-family: $typo-2;
      color: $color-black;
    }
  }
  &__social-network{
    display: flex;
  }
  &__social-network{
    a{
      display: flex;
      display: -ms-flexbox;
      justify-content: center;
      align-items: center;
      width: 3.8em;
      height: 2.5em;
      margin-right: 1em;
      border-radius: 1.5em;
      position: relative;
      -webkit-transition: .4s;
      transition: .4s;
      &:hover,
      &:focus{
        text-decoration: none;
        border-color: $color-2--2;
        .default-icon{
          opacity: 0;
        }
        .hover-icon{
          opacity: 1;
        }
      }
      span{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        display: -ms-flexbox;
        justify-content: center;
        align-items: center;
        -webkit-transition: .4s;
        transition: .4s;
        img{
          width: 100%;
          height: 100%;
          display: inline-block;
        }
      }
      .hover-icon{
        opacity: 0;
      }
    }
  }
  .search{
    background: $color-3--1;
    &:hover{
      background: $color-2--2;
    }
  }
}
.social-network__item{
  a{
    display: flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    img{
      width: auto;
      height: auto;
      max-width: 1.9em;
      max-height: 1.9em;
    }
  }
}
