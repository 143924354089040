// Galerie classique
.classical-gallery {
  max-width: 70rem;
}

.classical-gallery__title {
  $classical-gallery__title_font-size: 1.6;
  font-family: $typo-2;
  text-align: right;
  font-weight: $normal;
  font-size: #{$classical-gallery__title_font-size}em;
  color: $color-3--3;
  margin: em(3, $classical-gallery__title_font-size) 0 em(1, $classical-gallery__title_font-size) 0;
}

.classical-gallery {
  margin: 5.5em 0 3em;
  overflow: hidden;
  &__nb-items {
    display: none;
  }
}

.slider-galerie {
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 10px;
  .slider-galerie__item {
    width: 100%;
    height: 100%;
    z-index: 1 !important;
    border: none;
    background: $color-3--1;
  }
  .infos-img {
    max-width: 180px;
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 0;
    width: calc(100% - 510px);
    display: table;
    padding-top: 7px;
    font-family: $typo-2;
    font-weight: $light;
    font-style: italic;
    color: $color-3--3;
    font-size: 1.3em;
    text-align: left;
    white-space: normal;
    &__nbItems {
      display: table-cell;
      vertical-align: bottom;
      white-space: nowrap;
      width: 70px;
    }
    &__wrap {
      display: table-cell;
      vertical-align: top;
      span {
        font-style: italic;
        font-size: 12px;
        color:$color-2--3;
      }
    }
    &__legend {
      display: block;
    }
  }
  a {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
    position: relative;
    white-space: nowrap;
    padding: 68.08510% 0 0 0; // 100/(940/640)
    figure {
      display: inline;
    }
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 50%;
    right: 0;
    transform: translateX(-50%) translateY(-50%);
  }
}

.sliderVideo {
  margin-bottom: 0;
  .infos-img {
    position: inherit;
    bottom: inherit;
    top: inherit;
    left: inherit;
    right: inherit;
  }
}

.carousel-galerie {
  width: 500px;
  display: inline-block;
  text-align: left;
  position: relative;
  z-index: 1;
  .item {
    border: none;
  }
}

.carousel-galerie__thumb {
  width: 340px;
  height: 74px;
  z-index: 2;
  margin-left: 80px;
  text-align: left;
  &.no-carousel {
    position: relative;
    .carousel-galerie__item {
      display: inline-block;
      margin: 0 3px;
    }
  }
}
.carousel-galerie__item {
  z-index: 1 !important;
  text-align: center;
  &.cycle-slide-active button, &.cycle-pager-active button, button:hover, button:focus {
    &:before {
      @include background-opacity($color-2--3, .8)
    }
  }
  button {
    position: relative;
    &:before {
      transition: all ease .2s;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    img {
      width: 74px;
      height: 74px;
    }
    &:hover, &:focus {
      &:before{
        @include background-opacity($color-2--3, .8)
      }
    }
  }
}
.rte{
  .carousel-galerie__item {
    z-index: 1 !important;
    text-align: center;
    &.cycle-slide-active button, &.cycle-pager-active button, button:hover, button:focus {
      &:before {
        @include background-opacity($color-2--3, .8)
      }
    }
    button {
      position: relative;
      img {
        width: 74px;
        height: 74px;
      }
    }
    button{
      &:hover, &:focus {
        @include background-opacity($color-2--3, .8)
      }
    }
  }
}

.rte {
  .carousel-galerie__pager {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 74px;
    width: 500px;
    z-index: 1;
  }
}

.rte .carousel-galerie__prev, .rte .carousel-galerie__next,
.carousel-galerie__prev, .carousel-galerie__next{
  position: absolute;
  top: 0;
  padding: 0;
  button {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    background: $color-2--3;
    text-indent: -9999px;
    transition: all ease .2s;
    @include default-icons-absolute-before("\e017", 2.8em, $color-white, 50%, inherit, inherit, 50%);
    &:before {
      text-indent: 0;
      font-size: 37px;
      transform: translateY(-50%) translateX(-50%);
    }
    &:hover, &:focus {
      @include background-opacity($color-2--3, .8);
      outline:none;
    }
  }
  &:before {
    content: "";
    speak: none;
  }
}
.carousel-galerie__prev,
.rte .carousel-galerie__prev{
  left: 0;
  button {
    &:before {
      content: "\e016";
    }
  }
}
.rte .carousel-galerie__next,
.carousel-galerie__next {
  right: 0;
}
