// @name Liste type 1
// @description Basic list
.list-type-1__picture {
    img {
        max-width: 120px;
    }
}

.list-type-1__title {
    font-size: 1.6em;
}
