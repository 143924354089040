.vous-constatez{
  padding-left: 22em;
  &__text-block{
    width: 54%;
    padding: 2.9em 1em 2.5em 0;
    .text-container{
      width: 69%;
      padding-left: 18px;
      &__title{
        max-height: 4.2em;
        font-size: 1.7em;
        line-height: 1.25em;
        margin-bottom: 0;
        margin-top: -8px;
      }
      p{
        max-height: em(1.6, 1.2);
        font-size: 1.5em;
      }
    }
    .img-holder{
      margin-right: 20px;
    }
  }
  &__access-block{
    width: 41%;
    padding: 22px 18px 22px 9%;
    &::before{
      height: 33em;
    }
    .access-link{
      font-size: 1.5em;
      margin-right: 10px;
    }
    .more-link{
      width: 5.47em;
      height: 3.36em;
    }
  }
}
