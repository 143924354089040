.map {
  $map_font-size: 1em;
  overflow: hidden;
  margin: 7em 0 6em;
  width: 100%;
  .map__title{
    text-align: center;
    font-size: 3.8em;
    color: $color-black;
    margin-top: 3px;
    margin-bottom: .85em;
    &::before{
      background: $color-1--2;
      top: 0.3em;
    }
    .wrap{
      &::before{
        color: $color-1--2;
        left: -6px;
      }
      &::after{
        color: $color-1--2;
        right: -6px;
      }
      .inner{
        padding: 0 0.8em;
      }
    }
  }
  &__wrapper{
    position: relative;
  }
  #map {
    width: 100%;
    height: 467px;
  }
  .point__address-info{
    position: absolute;
    z-index: 2;
    top: 0;
    right: -220px;
    box-sizing: border-box;
    padding: 2.6em 3.4em 130px;
    background: $color-3--10;
    width: 220px;
    height: 100%;
    transition: right .5s;
    &.active{
      right: 0;
    }
    .cross{
      @include default-icons-absolute-before("\e08a", $size: 1.5em, $color: $color-white, $top: 2px, $left: 7px);
      position: absolute;
      top: 1em;
      right: 1em;
      background: $color-2--3;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      transition: .5s transform;
      &:before{
        transform: rotate(46deg);
      }
      &:hover{
          transform: rotate(90deg);
        &:before{
        }
      }
    }
    .point-title{
      margin: 0 0 0.4em;
      color: $color-2--3;
      font-size: 2em;
      font-family: $typo-3;
    }
    .list-infos{
      font-style: normal;
      font-size: $map_font-size;
      &__item{
        margin: 0 0 0.6em;
        padding-left: 1.1em;
        font-weight: $light;
        font-size: 1.4em;
        font-family: $typo-3;
        &:before{
          color: $color-2--3;
        }
        a{
          color: $color-black;
          text-decoration: none;
        }
      }
      &__phone{
        &:before{
          top: 0.4em;
          font-size: 0.8em;
        }
      }
      &__fax{
        &:before{
          top: 0.3em;
          font-size: 0.9em;
        }
      }
      &__address{
        margin: 0 0 0.7em;
        padding-left: 0;
        line-height: 1.2;
        &:before{
          display: none;
          content: "";
        }
      }
    }
  }
  .point__buttons{
    position: absolute;
    bottom: 3.9em;
    left: 3.3em;
    right: 4.7em;
    font-size: $map_font-size;
    text-align: center;
    button{
      display: block;
      margin: 0.7em 0 0;
      padding: 1em 2.14286em;
      width: 100%;
      font-weight: $bold;
      font-size: 1.3em;
      box-sizing: border-box;
      &:before{
        position: static;
        font-weight: $normal;
        transform: translateY(0);
      }
      &.btn-contact{
        @include default-icons-before('\e02a', 0 5px 0 0, 0.9em, $color-2--3, baseline);
      }
      &.btn-go-site{
        @include default-icons-before('\e034', 0 5px 0 0, 0.9em, $color-1--2, baseline);
      }
    }
    .button-1{
      background: rgba(85, 156, 168, 0.5);
      &:hover{
        background: rgba(85, 156, 168, 1);
      }
    }
  }
}
