// @name Videos
.videos {
  max-width: 70rem;
  margin: 2.7em 0 6.2em;
  .video__title {
    font-family: $typo-3;
    text-transform: none;
    font-weight: $normal;
    font-size: 1.6em;
    color: $color-black;
    padding-left: 0;
    margin: 0 0 .7em;
    &::before, &::after {
      display: none;
    }
  }
  div.iframe {
      border: 0;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
  }

  &__item {
    position: relative;
    width: 100%;
    margin: 0 auto 25px;
    video {
      width: 100%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__ratio {
    display: block;
    width: 100%;
    height: auto;
  }
  &__caption{
    display: none;
    font-size: 1.2em;
    font-weight: $light;
    font-style: italic;
    font-family: $typo-2;
    text-align: right;
    color: $color-2--3;
    @include default-icons-after("\e00f", 0 -5px 0 1px, 17px, $color-3--2);
    &::after{
      font-style: normal;
    }
  }
}

.ce-textpic {
  .videos,
  .videos__item {
    margin: 0;
  }
}

// @name Videos for popin
// @description if only one, no margin
.popin {
  #content {
    .setPos {
      &:only-of-type {
        .videos {
          margin: 0;
          .videos__item {
            &:only-of-type {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
