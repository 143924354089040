//// @state .home - Customize on the home
//.home {
//    .ddm-menu-service {
//        .menu-service {
//            .ddm__title {
//                top: inherit;
//                text-align: center;
//                padding: 1em 0;
//                br {
//                    display: none;
//                }
//                button {
//                    min-width: inherit;
//                    min-height: inherit;
//                    font-family: $typo-3;
//                    border: none;
//                    background: $color-white;
//                    color: $color-3--3;
//                    font-size: 1.2em;
//                    text-transform: uppercase;
//                    font-weight: $bold;
//                    padding: 2px 0 0 25px;
//                    margin-left: 10px;
//                    @include default-icons-absolute-before('\e014', 13px, $color-3--3, 50%, inherit, inherit, 0);
//                    &:before {
//                        border: 1px solid $color-3--3;
//                        width: 19px;
//                        height: 19px;
//                        line-height: 19px;
//                        border-radius: 50%;
//                        transition: all ease .2s;
//                        transform: translateY(-50%);
//                    }
//
//                    &::after {
//                        display: none;
//                    }
//                }
//            }
//        }
//        &.ddm--active {
//            .menu-service {
//                .ddm__title {
//                    button {
//                        &:before {
//                            content: "\e015";
//                        }
//                    }
//                }
//            }
//        }
//    }
//    .header__middle {
//        margin: 0 1.9em;
//    }
//}

.home,.page {
    .ddm-menu-service {
        position: relative;
    }
}

.service__log {
    display: none;
}

.service {
    display: block;
    .col1 {
        width: 100%;
        margin-bottom: 30px;
    }
    .col2 {
        margin: 0 0 40px;
    }
    .login-form {
        margin: 0 auto;
    }
}

.service__lesplusdemandes__listitems {
    margin-left: 0;
    text-align: center;
    li {
        margin: 0 2% 2.6%;
    }
}

.home .header.fixed-top-nav,
.header.fixed-top-nav {

    .ddm-menu-service.ddm--active {
        .menu-wrapper {
            display: none;
        }
    }
}
